import ActivitesDeLoisirs from '../../../components/atoms/icons/listeActivites/activitesDeLoisirs/ActivitesDeLoisirs';
import AutoMotoCycles from '../../../components/atoms/icons/listeActivites/autoMotoCycles/AutoMotoCycles';
import BarTabacPresseLotoPmu from '../../../components/atoms/icons/listeActivites/barTabacPresseLotoPmu/BarTabacPresseLotoPmu';
import EquipementDeLaMaison from '../../../components/atoms/icons/listeActivites/equipementDeLaMaison/EquipementDeLaMaison';
import EquipementDeLaPersonne from '../../../components/atoms/icons/listeActivites/equipementDeLaPersonne/EquipementDeLaPersonne';
import HotellerieRestauration from '../../../components/atoms/icons/listeActivites/hotellerieRestauration/HotellerieRestauration';
import MetiersDeLaBouche from '../../../components/atoms/icons/listeActivites/metiersDeLaBouche/MetiersDeLaBouche';
import ServicesALaPersonne from '../../../components/atoms/icons/listeActivites/servicesALaPersonne/ServicesALaPersonne';
import ServicesAuxEntreprises from '../../../components/atoms/icons/listeActivites/servicesAuxEntreprises/ServicesAuxEntreprises';
import SoinsALaPersonne from '../../../components/atoms/icons/listeActivites/soinsALaPersonne/SoinsALaPersonne';
import TpePmeArtisanat from '../../../components/atoms/icons/listeActivites/tpePmeArtisanat/TpePmeArtisanat';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import CardWithHeaders from '../../../components/molecules/cards/cardWithHeaders/CardWithHeaders';
import StickySearchbar from '../../../components/molecules/stickySearchbar/StickySearchbar';
import './ListeActivites.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Loader from '../../../components/organisms/loader/Loader';
import { sleep } from '../../../utils/Utils';
import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks';
import { fetchAllActivitiesApi } from '../../../api/OutilsApi';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../../context/AppContext';

function ListeActivites() {
  const [activities, setActivities] = useState([]);
  const [displayedActivities, setDisplayedActivities] = useState([]);
  const [containerHeight, setContainerHeight] = useState(5000);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const icons = {
    'ACTIVITÉS DE LOISIRS': <ActivitesDeLoisirs />,
    'AUTO - MOTO - CYCLES': <AutoMotoCycles />,
    'BAR TABAC PRESSE LOTO PMU': <BarTabacPresseLotoPmu />,
    'EQUIPEMENT DE LA PERSONNE': <EquipementDeLaMaison />,
    'EQUIPEMENT DE LA MAISON': <EquipementDeLaPersonne />,
    'HÔTELLERIE RESTAURATION': <HotellerieRestauration />,
    'MÉTIERS DE BOUCHE': <MetiersDeLaBouche />,
    'SERVICES AUX ENTREPRISES': <ServicesAuxEntreprises />,
    'SERVICES À LA PERSONNE': <ServicesALaPersonne />,
    'SOINS À LA PERSONNE': <SoinsALaPersonne />,
    'TPE - PME - ARTISANAT': <TpePmeArtisanat />,
  };

  const containerRef = useRef();
  const { width } = useWindowSize();
  const [windowScroll] = useWindowScroll();
  const { setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate()

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchActivities();
  }, []);

  useEffect(() => {
    updateContainerHeight();
  }, [displayedActivities]);

  useEffect(() => {
    setContainerHeight(width > 1200 ? 5000 : width > 576 ? 10000 : 20000);
  }, [width]);

  async function fetchActivities() {
    try {
      const activities = (await fetchAllActivitiesApi()).data;

      setActivities(activities);
      setDisplayedActivities(activities);
      setAppLoaderVisible(false);
    } catch (error) {
      navigate('/404')
    }
  }

  function handleSearchbarChange(value) {
    if (value.length < 1) {
      setDisplayedActivities(activities);
    } else {
      let filteredActivities = [];

      for (const category of activities) {
        if (category.activities) {
          const tmpActivities = category.activities.filter(activity =>
            activity.toLowerCase().includes(value.toLowerCase())
          );
          if (tmpActivities.length) filteredActivities.push({ category: category.category, activities: tmpActivities });
        }
      }

      setDisplayedActivities(filteredActivities);
    }
    if (windowScroll.y > 500) window.scrollTo(0, 0);
  }

  async function updateContainerHeight() {
    if (!containerRef.current || !containerRef.current.scrollHeight) return;

    setLoaderVisible(true);
    await sleep(200);
    setContainerHeight(width > 1200 ? 5000 : width > 576 ? 10000 : 20000);
    await sleep(1);

    while (
      containerRef.current.scrollWidth <= containerRef.current.clientWidth &&
      containerRef.current.scrollHeight <= containerRef.current.clientHeight &&
      containerRef.current.scrollHeight > 200
    ) {
      setContainerHeight(height => height - 100);
      if (
        containerRef.current.scrollWidth <= containerRef.current.clientWidth &&
        containerRef.current.scrollHeight <= containerRef.current.clientHeight
      )
        await sleep(1);
    }

    setContainerHeight(height => height + 100);
    setTimeout(() => {
      setLoaderVisible(false);
    }, 50);
  }

  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            { name: 'Accueil', to: '/' },
            { name: 'Estimer: Liste des activités estimables', to: '/liste-activites' },
          ]}
        />
        <Banner
          title='Liste des activités estimables'
          subtitle='Consultez la totalité des activités que vous pouvez estimer sur notre site'
        />
        <StickySearchbar onChange={handleSearchbarChange} />
        <div className='relative'>
          {!displayedActivities.length && (
            <p className='text-xl text-center outfit-semibold'>Aucun résultat ne correspond à votre recherche</p>
          )}
          <Loader visible={loaderVisible} className={'activities-loader'} />
          <div
            ref={containerRef}
            style={{ '--container-height': containerHeight + 'px' }}
            className={'liste-activites-container ' + ('cols-' + displayedActivities.length)}>
            {Array.isArray(displayedActivities) && displayedActivities.map((category, key) => (
              <CardWithHeaders
                key={key}
                paddingFooter='0'
                header={
                  <div className='flex space-between'>
                    <div className='activity-category'>{category.category}</div>
                    {icons[category.category.toUpperCase()]}
                  </div>
                }>
                <ul>
                  {category.activities.map((activity, key) => (
                    <li key={key}>{activity}</li>
                  ))}
                </ul>
              </CardWithHeaders>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default ListeActivites;
