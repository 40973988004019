import React from 'react';
import LogoEntreprisePdf from '../logoEntreprisePdf/LogoEntreprisePdf';
import H1Pdf from '../h1Pdf/H1Pdf';
import InfosBlockPdf from '../infosBlockPdf/InfosBlockPdf';

const CoverPagePdf = ({ logoUrl, reportTitle, businessInfo }) => {
  return (
    <div>
      <LogoEntreprisePdf width='400px' height='300px' src={logoUrl} alt="Logo de l'entreprise" />
      <H1Pdf shouldAppearOnSummary={0} fontSize='56px' title={reportTitle} className='mt-3xl' />
      <InfosBlockPdf infos={businessInfo} />
    </div>
  );
};

export default CoverPagePdf;
