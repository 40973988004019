import React, { useRef, useEffect } from 'react';
import './Chart.css';
import Chart from 'chart.js/auto';
import { formatNumberWithSpaces } from '../../../utils/Utils';

function BarChart({ data, onImageReady, verticalText = false}) {
    const canvasRef = useRef(null);
    let chartInstance = useRef(null);

    useEffect(() => {
        function calculateScale(data) {
            const allData = data.datasets.flatMap(dataset => dataset.data);
            let max = Math.max(...allData);
            let min = Math.min(...allData);

            max = Math.ceil(max / 100000) * 100000 + 300000;
            min = Math.floor(min / 100000) * 100000 - 100000;

            if (min >= 0) min = 0;
            if (max <= 0) max = 0;

            return { max, min };
        }

        const { max, min } = calculateScale(data);

        const options = {
            scales: {
                x: {},
                y: {
                    max: max,
                    min: min,
                    ticks: {
                        callback: function(value) {
                            return `${formatNumberWithSpaces(value)} €`;
                        }
                    }
                }
            },
            animation: {
                duration: 0,
                onComplete: function() {
                    const ctx = this.ctx;
                    this.config.data.datasets.forEach((dataset, i) => {
                        const meta = this.getDatasetMeta(i);
                        meta.data.forEach((bar, index) => {
                            const data = dataset.data[index];
                            ctx.fillStyle = 'rgba(137, 17, 71, 1)';
                            const text = `${formatNumberWithSpaces(data)} €`;
                            const barWidth = bar.width;
                            const fontSize = Math.max(8.4, Math.min(10, barWidth / text.length * 2));                           
                            ctx.font = `${fontSize}px Helvetica`;
                            const textWidth = ctx.measureText(text).width;
                            const positionX = bar.x - textWidth / 2;
                            const positionY = data >= 0 ? bar.y - 6 : bar.y + 18;

                            if (verticalText) {
                                ctx.save();
                                ctx.translate(positionX + textWidth / 2, positionY);
                                ctx.rotate(-Math.PI / 2);
                                ctx.fillText(text, 0, 0);
                                ctx.restore();
                            } else {
                                ctx.fillText(text, positionX, positionY);
                            }
                        });
                    });

                    const imageUrl = canvasRef.current.toDataURL();
                    onImageReady(imageUrl);
                }
            },
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true,
                        boxWidth: 30,
                    },
                    display: true,
                    position: "bottom",
                },
                tooltip: {
                    enabled: false,
                },
            }
        };

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const ctx = canvasRef.current.getContext('2d');
        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data,
            options
        });

        return () => chartInstance.current && chartInstance.current.destroy();
    }, [data, onImageReady, verticalText]);

    return (
        <div className='bar-chart-container'>
            <canvas ref={canvasRef} style={{ display: 'none'}}></canvas>
        </div>
    );
};

export default BarChart;