import axios from 'axios';
import { logoutUserApi } from './CompteApi';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

// Add Bearer token to all requests
axiosInstance.interceptors.request.use(
  config => {
    const uuid = localStorage.getItem('uuid');
    if (uuid) {
      config.headers['Authorization'] = 'Bearer ' + uuid;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Logout user if bad credentials
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response.status === 403 && error.response.data.infos.code === '05') {
      await logoutUserApi();
      localStorage.removeItem('uuid');
      window.location.replace('/connexion?state=bad_credentials');
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
