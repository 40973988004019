import { useNavigate, useSearchParams } from 'react-router-dom';
import ConnexionForm from '../../../components/forms/compte/connexionForm/ConnexionForm';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import AppContext from '../../../context/AppContext';
import './Connexion.css';
import React, { useContext, useEffect } from 'react';

function Connexion() {
  const { getUserId, createNotification } = useContext(AppContext)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  // Display notification if user is redirected by axios config
  useEffect(() => {
    if(searchParams.get('state') === 'bad_credentials') {
      createNotification(<>Vous n'êtes pas authorisé à accéder à cette fonctionnalité. Veuillez vous reconnecter</>, 'var(--red)', 'var(--grey)')
      searchParams.delete('state')
      setSearchParams(searchParams)
    }
  }, [])

  useEffect(() => {
    if(getUserId() && !localStorage.getItem('account_not_activated')) navigate('/mon-compte/mon-profil')
  }, [getUserId()])

  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            { name: 'Accueil', to: '/' },
            { name: 'Connexion', to: '/connexion' },
          ]}
        />
        <Banner title='Connexion' subtitle='Bienvenue, connectez-vous afin de profiter de nos services' />
        <div className='connexion-page-container'>
          <ConnexionForm />
          </div>
      </section>
    </>
  );
}

export default Connexion;
