import React from 'react';
import './PagePdf.css';
import PaginationPdf from '../paginationPdf/PaginationPdf';

function PagePdf({children, pageActuelle, totalPages}) {
  return (
    <div className="page-pdf" custom-page-number={pageActuelle}>
      {children}
      <div className="page-number">
        <PaginationPdf currentPage={pageActuelle} totalPages={totalPages} />
      </div>
    </div>
  );
}

export default PagePdf;