import { useWindowSize } from '@uidotdev/usehooks';
import React, { useEffect } from 'react';
import Card from '../../cards/card/Card';
import DoubleInput from '../doubleInput/DoubleInput';
import Trash from '../../../atoms/icons/general/trash/Trash';
import FilledButton from '../../buttons/filledButton/FilledButton';
import Add from '../../../atoms/icons/general/add/Add';
import Input from '../input/Input';
import './MultiplesInputs.css';
import { removeSpaces } from '../../../../utils/Utils';

// inputType = 1 => DoubleInput / inputType = 2 => 2 Inputs

function MultiplesInputs({
  className = '',
  values,
  setValues,
  name,
  unregister,
  Form,
  inputType = 1,
  label,
  visible = true,
  onInputChange = () => {},
  secondaryParcours,
}) {
  const { width } = useWindowSize();

  useEffect(() => {
    onInputChange();
  }, [values]);

  useEffect(() => {
    if (!visible) {
      values.map(value => {
        if (inputType === 1) unregister([`${name}.${value.index}.input`, `${name}.${value.index}.value`]);
        else unregister([`${name}.${value.index}.name`, `${name}.${value.index}.description`]);
      });
      setValues([]);
    } else {
      if (!values.length) {
        addRow();
      }
    }
  }, [visible]);

  function deleteRow(index) {
    const tmp = [...values];
    tmp[index].visible = false;
    setValues(tmp);
    onInputChange();

    if (inputType === 1) unregister([`${name}.${index}.input`, `${name}.${index}.value`]);
    else unregister([`${name}.${index}.name`, `${name}.${index}.description`]);
  }

  function addRow() {
    const tmp = [...values];
    tmp.push({ index: tmp.length, visible: true });
    setValues(tmp);
    onInputChange();

    setTimeout(() => {
      document.getElementById(`${name}.${tmp.length - 1}.${inputType === 1 ? 'input' : 'name'}`).focus();
    }, 200);
  }

  function calcInputError(e, index) {
    const tmp = [...values];
    let hasError = false;

    if (!e.target.value) {
      tmp[index].error = 'Ce champ est requis';
      hasError = true;
    } else {
      tmp[index].error = '';
    }

    setValues(tmp);
    return hasError;
  }

  return (
    <div className={`multiple-inputs-container ${className}`}>
      {label ? visible ? <label className='form-label'>{label}</label> : null : null}
      {values.map(
        value =>
          value.visible && (
            <Card key={value.index} bgColor='var(--light-grey)' padding='20px 20px 4px'>
              <div className='row'>
                {inputType === 1 ? (
                  <DoubleInput
                    disabled1={secondaryParcours}
                    inputType={inputType}
                    onBlur1={e => calcInputError(e, value.index)}
                    defaultValue={0}
                    name1={`${name}.${value.index}.input`}
                    name={`${name}.${value.index}.value`}
                    placeholder1='Intitulé'
                    placeholder2='Montant'
                    onChange1={e => calcInputError(e, value.index)}
                    onChange={onInputChange}
                    useForm={Form}
                    icon='euro'
                    error={values[value.index].error}
                  />
                ) : (
                  <div className='two-inputs-container'>
                    <Input
                      name={`${name}.${value.index}.name`}
                      label='Nom'
                      register={Form.register}
                      error={values[value.index].error}
                      onBlur={e => calcInputError(e, value.index)}
                      onChange={e => calcInputError(e, value.index)}
                    />
                    <Input
                      name={`${name}.${value.index}.description`}
                      label='Description'
                      register={Form.register}
                      error={values[value.index].error}
                      onBlur={e => calcInputError(e, value.index)}
                      onChange={e => {
                        calcInputError(e, value.index);
                        onInputChange(e);
                      }}
                    />
                  </div>
                )}
                {!secondaryParcours && (
                  <div className='pb-md px-sm centered'>
                    <Trash onClick={() => deleteRow(value.index)} />
                  </div>
                )}
              </div>
            </Card>
          )
      )}
      {secondaryParcours ? null : visible ? (
        <FilledButton onClick={addRow}>
          <Add width={width > 576 ? '30px' : '20px'} /> Ajouter une ligne
        </FilledButton>
      ) : null}
    </div>
  );
}

export default MultiplesInputs;

export function haveMultiplesInputsErrors(values) {
  if (!values) return false;
  if (values.some(value => value.error)) return true;
}

export function removeSpacesFromMultiplesInputs(values) {
  if (!values) return [];

  return values
    .map(value => {
      if (value.input && value.value) return { input: value.input, value: removeSpaces(value.value) };
    })
    .filter(value => value);
}

export function calcTotalFormMultipleInputs(values, states) {
  let total = 0;

  states.forEach(state => {
    if (state.visible && values && values[state.index]) {
      total += removeSpaces(values[state.index].value);
    }
  });

  return total;
}

export function fillMultipleInputs(inputsValues, setValues, setState, name) {
  const state = [];
  const values = [];

  if (inputsValues)
    inputsValues.map((value, index) => {
      state.push({ index, visible: true });
      values.push({ ...value });
    });

  setState(state);
  setValues(name, values);
}
