import React, { useRef, useEffect } from 'react';
import './Chart.css';
import Chart from 'chart.js/auto';
import { formatNumberWithSpaces } from '../../../utils/Utils';

function DoughnutChart({ turnover, payroll, rentAndCharges, onImageReady, performanceYear }) {
  const canvasRef = useRef(null);

  function formatNaNOrUndefined(value) {
    return isNaN(value) || value === undefined ? 0 : value;  
  }

  useEffect(() => {
    const formattedTurnover = formatNaNOrUndefined(turnover);
    const formattedPayroll = formatNaNOrUndefined(payroll);
    const formattedRentAndCharges = formatNaNOrUndefined(rentAndCharges);

    const payrollPercentage = ((formattedPayroll / formattedTurnover) * 100).toFixed(2);
    const rentAndChargesPercentage = ((formattedRentAndCharges / formattedTurnover) * 100).toFixed(2);
    const remainingPercentage = (100 - payrollPercentage - rentAndChargesPercentage).toFixed(2);

    const chartConfig = {
      type: 'doughnut',
      data: {
        labels: [
          `Chiffre d'affaires (${formatNumberWithSpaces(turnover)} €)`,
          `Masse salariale (${formatNumberWithSpaces(payroll)} € - ${payrollPercentage}%)`,
          `Loyer et charges (${formatNumberWithSpaces(rentAndCharges)} € - ${rentAndChargesPercentage}%)`
        ],
        datasets: [
          {
            label: 'Chiffre d\'affaires',
            data: [100, 0, 0],
            backgroundColor: ['rgba(202, 148, 172, 0.8)'],
            borderWidth: 4,
          },
          {
            data: [remainingPercentage, payrollPercentage, rentAndChargesPercentage],
            backgroundColor: [
              'rgba(255, 255, 255, 0.8)',
              'rgba(109, 121, 164, 0.8)', 
              'rgba(137, 17, 71, 0.8)'
            ],
          }
        ]
      },
      options: {
        responsive: true,
        animation: {
          onComplete: () => {
            const imageUrl = canvasRef.current.toDataURL();
            onImageReady(imageUrl);
          }
        },
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            labels: {
              color: 'rgb(37, 37, 37)',
              boxWidth: 30,
              padding: 20,
              generateLabels: chart => {
                const data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  const colors = [data.datasets[0].backgroundColor[0], ...data.datasets[1].backgroundColor.slice(1)];
                  return data.labels.map((label, i) => ({
                    text: label,
                    fillStyle: colors[i],
                    strokeStyle: colors[i],
                    hidden: false,
                    index: i
                  }));
                }
                return [];
              }
            }
          },
          title: {
            display: true,
            text: performanceYear,
          },
          tooltip: {
            callbacks: {
              label: tooltipItem => {
                let label = chartConfig.data.labels[tooltipItem.dataIndex] || '';
                return label;
              }
            }
          }
        }
      },
    };

    const chart = new Chart(canvasRef.current, chartConfig);

    return () => {
      chart.destroy();
    };
  }, [turnover, payroll, rentAndCharges, onImageReady, performanceYear]);

  return (
    <div className='doughnut-chart-container' style={{ maxWidth: '550px', maxHeight: '550px' }}>
      <canvas ref={canvasRef} style={{ display: 'none' }}/>
    </div>
  );
}

export default DoughnutChart;