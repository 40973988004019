import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../context/AppContext';
import Input from '../../../molecules/formComponents/input/Input';
import UnfilledButton from '../../../molecules/buttons/unfilledButton/UnfilledButton';
import FilledButton from '../../../molecules/buttons/filledButton/FilledButton';

function AddBilanYearModal({bilanYear , onSubmit }) {
    const [value, setValue] = useState('')
  const { setModalVisible } = useContext(AppContext);

  useEffect(() => {
    setValue(bilanYear)
  }, [bilanYear])

  return (
    <form>
      <Input value={value} onChange={e => setValue(e.target.value)} type='date' label='Date du bilan' />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' onClick={() => onSubmit(value)}>
          Ajouter le bilan
        </FilledButton>
      </div>
    </form>
  );
}

export default AddBilanYearModal;
