import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/monCompte/rapportLogo.svg';

function RapportLogo({ width = '50px', rotate = '0', className = '', onClick }) {
  return (
    <Icon
      style={{ transform: 'rotate(' + rotate + ')', cursor: onClick ? 'pointer' : 'auto' }}
      width={width}
      height={width}
      className={className + (onClick ? ' clickable-icon' : '')}
      onClick={onClick}
    />
  );
}

export default RapportLogo;
