import { generateHrefPdf } from '../summaryPdf/SummaryPdf';
import './H3Pdf.css';
import React from 'react';

function H3Pdf({ title, className = '', shouldAppearOnSummary = 1 }) {
  return (
    <div id={generateHrefPdf(title)} className={`text-hr-container ${className}`}>
      <h3 custom-visibility={shouldAppearOnSummary}>{title}</h3>
      <div className='text-hr-side' />
    </div>
  );
}

export default H3Pdf;
