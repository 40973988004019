import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/general/magnifier.svg';

function Magnifier({ width = '20px', color = 'var(--grey)', className = '', onClick }) {
  return (
    <Icon
      style={{ '--color': color, 'stroke': 'var(--color)' }}
      width={width}
      className={className + (onClick ? ' clickable-icon' : '')}
      onClick={onClick}
    />
  );
}

export default Magnifier;
