import React, { useContext, useEffect } from 'react';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import { useForm } from 'react-hook-form';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import {
  formatNumberWithSpaces,
  formatObjectForPosting,
  getTotalFromValues,
  removeSpaces,
} from '../../../../../utils/Utils';
import { max2500Validation } from '../../../../../utils/formValidation/FormValidation';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import Input from '../../../../molecules/formComponents/input/Input';

function ComptesResultat({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const Form = { watch, register, setValue };
  const {
    moveToNextStep,
    getStepToGo,
    typesCommerce,
    setGoToStep,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    fillInputsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);
  const recapWatcher = watch([
    'ventes_marchandise',
    'achats_marchandise',
    'marge_commerciale',
    'taux_marge_commerciale',
    'production_prestations_vendues',
    'achats_matiere_premiere',
    'marge_sur_productions',
    'taux_marge_productions',
    'marge_brute_globale',
    'taux_marge_brute_globale',
    'vente_marchandise_hebergement',
    'ventes_marchandise_restauration',
  ]);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'compte_resultat',
      [
        'ventes_marchandise',
        'ventes_marchandise_hebergement',
        'ventes_marchandise_restauration',
        'achats_marchandise',
        'marge_commerciale',
        'total_commissions_nettes',
        'production_prestations_vendues',
        'achats_matiere_premiere',
        'marge_sur_productions',
        'ca_global',
        'marge_brute_globale',
        'achats_charges_externes',
        'valeur_ajoutee',
        'subvention_exploitation',
        'impots_taxes',
        'charges_personnel',
        'excedent_brut_exploitation',
        'reprises_sur_provisions_transfert_charges',
        'autres_produits',
        'dotations_amortissements_provisions',
        'autres_charges',
        'resultat_exploitation',
        'produits_financiers',
        'charges_financieres',
        'resultat_financier',
        'resultat_courant',
        'produits_exceptionnels',
        'charges_exceptionnelles',
        'resultat_exceptionnel',
        'resultat_exercice',
        'note_compte_resultat',
      ],
      setValue,
      estimationValues
    );

    setValue('note_detail_marge', estimationValues?.detail_marge?.note_detail_marge);

    calcTauxMargeCommerciale();
    calcTauxMargeProductions();
    calcMargeBruteGlobale();
    calcTauxMargeBruteGlobale();
  }, [estimationValues]);

  async function formSubmit(values) {
    const note = values.note_compte_resultat;
    delete values.note_compte_resultat;
    const note1 = values.note_detail_marge;
    delete values.note_detail_marge;

    const formattedValues = formatObjectForPosting(values);
    const detailMargeValues = {
      taux_marge_commerciale: formattedValues.taux_marge_commerciale,
      taux_marge_productions: formattedValues.taux_marge_productions,
      marge_brute_globale: formattedValues.marge_brute_globale,
      taux_marge_brute_globale: formattedValues.taux_marge_brute_globale,
    };

    delete formattedValues.taux_marge_commerciale;
    delete formattedValues.taux_marge_productions;
    delete formattedValues.taux_marge_brute_globale;

    const tmp = {
      compte_resultat: formattedValues,
      detail_marge: detailMargeValues,
    };

    if (!secondaryParcours) tmp.compte_resultat.note_compte_resultat = note;
    if (!secondaryParcours) tmp.detail_marge.note_detail_marge = note1;

    postValues(tmp);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  //calcul auto des inputs
  function calcMargeCommerciale() {
    const tmp = getValues(['ventes_marchandise', 'ventes_marchandise_hebergement', 'ventes_marchandise_retauration']);

    setValue(
      'marge_commerciale',
      removeSpaces(getTotalFromValues(tmp), true) - removeSpaces(getValues('achats_marchandise'), true)
    );

    calcMargeBruteGlobale();
    calcValeurAjoutee();
    calcTauxMargeCommerciale();
    calcMargeBruteGlobale();
  }

  function calcMargeSurProductions() {
    const tmp = getValues(['production_prestations_vendues', 'total_commissions_nettes', 'achats_matiere_premiere']);

    setValue(
      'marge_sur_productions',
      removeSpaces(tmp[0], true) + removeSpaces(tmp[1], true) - removeSpaces(tmp[2], true)
    );

    calcMargeBruteGlobale();
    calcTauxMargeProductions();
    calcMargeBruteGlobale();
  }

  function calcCaGlobal() {
    const tmp = getValues([
      'ventes_marchandise',
      'ventes_marchandise_hebergement',
      'ventes_marchandise_retauration',
      'production_prestations_vendues',
      'total_commissions_nettes',
    ]);

    setValue('ca_global', getTotalFromValues(tmp));
  }

  function calcMargeBruteGlobale() {
    const tmp = getValues(['marge_sur_productions', 'marge_commerciale']);

    setValue('marge_brute_globale', getTotalFromValues(tmp));
  }

  function calcValeurAjoutee() {
    const tmp = getValues(['marge_brute_globale', 'achats_charges_externes']);

    setValue('valeur_ajoutee', removeSpaces(tmp[0], true) - removeSpaces(tmp[1], true));

    calcEbe();
  }

  function calcEbe() {
    const tmp = getValues(['valeur_ajoutee', 'subvention_exploitation', 'impots_taxes', 'charges_personnel']);

    setValue(
      'excedent_brut_exploitation',
      removeSpaces(tmp[0], true) + removeSpaces(tmp[1], true) - removeSpaces(tmp[2], true) - removeSpaces(tmp[3], true)
    );

    calcResultatExploitation();
  }

  function calcResultatExploitation() {
    const tmp1 = getValues([
      'excedent_brut_exploitation',
      'reprises_sur_provisions_transfert_charges',
      'autres_produits',
    ]);
    const tmp2 = getValues(['dotations_amortissements_provisions', 'autres_charges']);

    setValue(
      'resultat_exploitation',
      removeSpaces(getTotalFromValues(tmp1), true) - removeSpaces(getTotalFromValues(tmp2), true)
    );

    calcResultatCourant();
  }

  function calcResultatFinancier() {
    const tmp = getValues(['produits_financiers', 'charges_financieres']);

    setValue('resultat_financier', removeSpaces(tmp[0], true) - removeSpaces(tmp[1], true));

    calcResultatCourant();
  }

  function calcResultatCourant() {
    const tmp = getValues(['resultat_exploitation', 'resultat_financier']);

    setValue('resultat_courant', getTotalFromValues(tmp));

    calcResultatExercice();
  }

  function calcResultatExceptionnel() {
    const tmp = getValues(['produits_exceptionnels', 'charges_exceptionnelles']);

    setValue('resultat_exceptionnel', removeSpaces(tmp[0], true) - removeSpaces(tmp[1], true));

    calcResultatExercice();
  }

  function calcResultatExercice() {
    const tmp = getValues(['resultat_courant', 'resultat_exceptionnel']);

    setValue('resultat_exercice', getTotalFromValues(tmp));
  }

  function calcTauxMargeCommerciale() {
    let values = getValues([
      'marge_commerciale',
      'ventes_marchandise',
      'ventes_marchandise_hebergement',
      'ventes_marchandise_retauration',
    ]);

    values = values.map(value => removeSpaces(value, true));
    setValue('taux_marge_commerciale', ((values[0] / (values[1] + values[2] + values[3])) * 100).toFixed(2));
  }

  function calcTauxMargeProductions() {
    let values = getValues(['marge_sur_productions', 'production_prestations_vendues', 'total_commissions_nettes']);

    values = values.map(value => removeSpaces(value, true));
    setValue('taux_marge_productions', ((values[0] / (values[1] + values[2])) * 100).toFixed(2));
  }

  function calcTauxMargeBruteGlobale() {
    let values = getValues([
      'marge_brute_globale',
      'ventes_marchandise',
      'ventes_marchandise_hebergement',
      'ventes_marchandise_retauration',
      'total_commissions_nettes',
      'production_prestations_vendues',
    ]);

    values = values.map(value => removeSpaces(value, true));
    setValue(
      'taux_marge_brute_globale',
      ((values[0] / (values[1] + values[2] + values[3] + values[4] + values[5])) * 100).toFixed(2)
    );
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>Compte de résultat</h2>
        <div className='row-1000 gap-sm'>
          <div className='w-100'>
            <NumberInput
              visible={!typesCommerce.includes(1)}
              useForm={Form}
              label={typesCommerce.includes(5) ? 'Ventes de marchandises (hors commissions)' : 'Ventes de marchandises'}
              name='ventes_marchandise'
              error={errors?.ventes_marchandise && errors.ventes_marchandise.message}
              icon='euro'
              onChange={() => {
                calcMargeCommerciale();
                calcCaGlobal();
                calcTauxMargeCommerciale();
                calcTauxMargeBruteGlobale();
              }}
            />
            <NumberInput
              visible={typesCommerce.includes(1)}
              useForm={Form}
              label={
                typesCommerce.includes(5)
                  ? 'Ventes de marchandises (hébergement)'
                  : ' Ventes de marchandises (hébergement / matelas)'
              }
              name='ventes_marchandise_hebergement'
              error={errors?.ventes_marchandise_hebergement && errors.ventes_marchandise_hebergement.message}
              icon='euro'
              onChange={() => {
                calcMargeCommerciale();
                calcCaGlobal();
                calcTauxMargeCommerciale();
                calcTauxMargeBruteGlobale();
              }}
            />
            <NumberInput
              visible={typesCommerce.includes(1)}
              useForm={Form}
              label='Ventes de marchandises (restauration et divers)'
              name='ventes_marchandise_retauration'
              error={errors?.ventes_marchandise_retauration && errors.ventes_marchandise_retauration.message}
              icon='euro'
              onChange={() => {
                calcMargeCommerciale();
                calcCaGlobal();
                calcTauxMargeCommerciale();
                calcTauxMargeBruteGlobale();
              }}
            />
            <NumberInput
              useForm={Form}
              label='Achats de marchandises'
              name='achats_marchandise'
              error={errors?.achats_marchandise && errors.achats_marchandise.message}
              icon='euro'
              onChange={() => {
                calcMargeCommerciale();
                calcCaGlobal();
                calcTauxMargeCommerciale();
                calcTauxMargeBruteGlobale();
              }}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Marge commerciale'
              name='marge_commerciale'
              error={errors?.marge_commerciale && errors.marge_commerciale.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label='Production / prestations vendues'
              name='production_prestations_vendues'
              error={errors?.production_prestations_vendues && errors.production_prestations_vendues.message}
              icon='euro'
              onChange={() => {
                calcMargeSurProductions();
                calcCaGlobal();
                calcTauxMargeProductions();
                calcTauxMargeBruteGlobale();
              }}
            />
            <NumberInput
              useForm={Form}
              visible={typesCommerce.includes(1) || typesCommerce.includes(5)}
              label='Total des commissions nettes'
              name='total_commissions_nettes'
              error={errors?.total_commissions_nettes && errors.total_commissions_nettes.message}
              icon='euro'
              onChange={() => {
                calcMargeSurProductions();
                calcCaGlobal();
                calcTauxMargeProductions();
                calcTauxMargeBruteGlobale();
              }}
            />
            <NumberInput
              useForm={Form}
              label='Achats de matières 1ères'
              name='achats_matiere_premiere'
              error={errors?.achats_matiere_premiere && errors.achats_matiere_premiere.message}
              icon='euro'
              onChange={calcMargeSurProductions}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Marge sur production'
              name='marge_sur_productions'
              error={errors?.marge_sur_productions && errors.marge_sur_productions.message}
              icon='euro'
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label="Chiffre d'affaires global"
              name='ca_global'
              error={errors?.ca_global && errors.ca_global.message}
              icon='euro'
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='marge brute globale'
              name='marge_brute_globale'
              error={errors?.marge_brute_globale && errors.marge_brute_globale.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label='Autres achats & charges externes'
              name='achats_charges_externes'
              error={errors?.achats_charges_externes && errors.achats_charges_externes.message}
              icon='euro'
              onChange={calcValeurAjoutee}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Valeur ajoutée'
              name='valeur_ajoutee'
              error={errors?.valeur_ajoutee && errors.valeur_ajoutee.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label="Subventions d'exploitation"
              name='subvention_exploitation'
              error={errors?.subvention_exploitation && errors.subvention_exploitationmessage}
              icon='euro'
              onChange={calcEbe}
            />
            <NumberInput
              useForm={Form}
              label='Impôts & taxes'
              name='impots_taxes'
              error={errors?.impots_taxes && errors.impots_taxes.message}
              icon='euro'
              onChange={calcEbe}
            />
            <NumberInput
              useForm={Form}
              label='Charges de personnel'
              name='charges_personnel'
              error={errors?.charges_personnel && errors.charges_personnel.message}
              icon='euro'
              onChange={calcEbe}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label="Excédent brut d'exploitation"
              name='excedent_brut_exploitation'
              error={errors?.excedent_brut_exploitation && errors.excedent_brut_exploitation.message}
              icon='euro'
            />
          </div>
          <div
            style={{ minHeight: '100%', width: '5px', backgroundColor: 'var(--secondary-color)', borderRadius: '3px' }}
          />
          <div className='w-100'>
            <NumberInput
              useForm={Form}
              label='Reprises s/ amort, dépréciat° et provis°. transferts de charges'
              name='reprises_sur_provisions_transfert_charges'
              error={
                errors?.reprises_sur_provisions_transfert_charges &&
                errors.reprises_sur_provisions_transfert_charges.message
              }
              icon='euro'
              onChange={calcResultatExploitation}
            />
            <NumberInput
              useForm={Form}
              label='Autres produits'
              name='autres_produits'
              error={errors?.autres_produits && errors.autres_produits.message}
              icon='euro'
              onChange={calcResultatExploitation}
            />
            <NumberInput
              useForm={Form}
              label='Dotations aux amortissements et provisions'
              name='dotations_amortissements_provisions'
              error={errors?.dotations_amortissements_provisions && errors.dotations_amortissements_provisions.message}
              icon='euro'
              onChange={calcResultatExploitation}
            />
            <NumberInput
              useForm={Form}
              label='Autres charges'
              name='autres_charges'
              error={errors?.autres_charges && errors.autres_charges.message}
              icon='euro'
              onChange={calcResultatExploitation}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label="Résultat d'exploitation"
              name='resultat_exploitation'
              error={errors?.resultat_exploitation && errors.resultat_exploitation.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label='Produits financiers'
              name='produits_financiers'
              error={errors?.produits_financiers && errors.produits_financiers.message}
              icon='euro'
              onChange={calcResultatFinancier}
            />
            <NumberInput
              useForm={Form}
              label='Charges financières'
              name='charges_financieres'
              error={errors?.charges_financieres && errors.charges_financieres.message}
              icon='euro'
              onChange={calcResultatFinancier}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Résultat financier'
              name='resultat_financier'
              error={errors?.resultat_financier && errors.resultat_financier.message}
              icon='euro'
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Résultat courant'
              name='resultat_courant'
              error={errors?.resultat_courant && errors.resultat_courant.message}
              icon='euro'
            />
            <NumberInput
              useForm={Form}
              label='Produits exceptionnels'
              name='produits_exceptionnels'
              error={errors?.produits_exceptionnels && errors.produits_exceptionnels.message}
              icon='euro'
              onChange={calcResultatExceptionnel}
            />
            <NumberInput
              useForm={Form}
              label='Charges exceptionnelles'
              name='charges_exceptionnelles'
              error={errors?.charges_exceptionnelles && errors.charges_exceptionnelles.message}
              icon='euro'
              onChange={calcResultatExceptionnel}
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label='Résultat exceptionnel'
              name='resultat_exceptionnel'
              error={errors?.resultat_exceptionnel && errors.resultat_exceptionnel.message}
              icon='euro'
            />
            <NumberInput
              bgColor='var(--main-color)'
              disabled
              validation={{}}
              useForm={Form}
              label="Résultat de l'exercice"
              name='resultat_exercice'
              error={errors?.resultat_exercice && errors.resultat_exercice.message}
              icon='euro'
            />
          </div>
        </div>
        {!secondaryParcours && (
          <Textarea
            register={register}
            name='note_compte_resultat'
            label='notes et remarques'
            className='mt-md'
            error={errors?.note_compte_resultat && errors.note_compte_resultat.message}
            validation={max2500Validation}
          />
        )}
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2 className='mb-3'>Détail de l'activité et de la marge</h2>
        <Input
          validation={{}}
          className='d-none'
          type='hidden'
          name='taux_marge_commerciale'
          disabled
          register={register}
        />
        <Input
          validation={{}}
          className='d-none'
          type='hidden'
          name='taux_marge_productions'
          disabled
          register={register}
        />
        <Input
          validation={{}}
          className='d-none'
          type='hidden'
          name='taux_marge_brute_globale'
          disabled
          register={register}
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
        />
        <div className='recap-ebe'>
          <div className='recap-row'>
            <p>Ventes de marchandises</p>
            <p>{getTotalFromValues([recapWatcher[0], recapWatcher[10], recapWatcher[11]])}€</p>
          </div>
          <div className='recap-row'>
            <p>Achats de marchandises</p>
            <p>{formatNumberWithSpaces(recapWatcher[1])}€</p>
          </div>
          <div className='recap-row'>
            <p>Marge commerciale</p>
            <p>{formatNumberWithSpaces(recapWatcher[2])}€</p>
          </div>
          <div className='recap-row'>
            <p>Taux de marge commerciale</p>
            <p>{formatNumberWithSpaces(recapWatcher[3])}%</p>
          </div>
          <div className='recap-row'>
            <p>Production / Prestations vendues</p>
            <p>{formatNumberWithSpaces(recapWatcher[4])}€</p>
          </div>
          <div className='recap-row'>
            <p>Achats de matières</p>
            <p>{formatNumberWithSpaces(recapWatcher[5])}€</p>
          </div>
          <div className='recap-row'>
            <p>Marge sur productions</p>
            <p>{formatNumberWithSpaces(recapWatcher[6])}€</p>
          </div>
          <div className='recap-row'>
            <p>Taux de marge sur productions</p>
            <p>{formatNumberWithSpaces(recapWatcher[7])}%</p>
          </div>
          <div className='recap-row'>
            <p>Marge brute globale</p>
            <p>{formatNumberWithSpaces(recapWatcher[8])}€</p>
          </div>
          <div className='recap-row'>
            <p>Taux de marge brute globale</p>
            <p>{formatNumberWithSpaces(recapWatcher[9])}%</p>
          </div>
        </div>
        {!secondaryParcours && (
          <Textarea
            register={register}
            name='note_detail_marge'
            label='notes et remarques'
            className='mt-md'
            error={errors?.note_detail_marge && errors.note_detail_marge.message}
            validation={max2500Validation}
            onFocus={() => {
              if (step == index + 1) setStep(index);
            }}
            onKeyDown={e => {
              if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                handleSubmit(formSubmit)();
              }
            }}
          />
        )}
      </fieldset>
    </form>
  );
}

export default ComptesResultat;
