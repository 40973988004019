import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/general/immeuble.svg';

function Immeuble({ width = '20px', color = 'var(--grey)', className }) {
  return (
    <Icon
      style={{
        '--color': color,
      }}
      fill='var(--color)'
      width={width}
      height={width}
      className={className}
    />
  );
}

export default Immeuble;
