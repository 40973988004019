import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../../../molecules/formComponents/input/Input';
import './TrouverSociete.css';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Info from '../../../../atoms/icons/general/info/Info';

function TrouverSociete({ id, index }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const {
    step,
    showTooltip,
    moveToNextStep,
    setStep,
    submitFormsFdc,
    setRaisonSocialeWatcher,
    estimationValues,
    getStepToGo,
    setGoToStep,
  } = useContext(ParcoursContext);
  const raisonSocialeWatcher = watch('raison_sociale');

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
    setTimeout(() => {
      setRaisonSocialeWatcher('');
    }, 1000);
  }, []);

  useEffect(() => {
    setValue('raison_sociale', estimationValues?.infos?.raison_sociale);
  }, [estimationValues]);

  useEffect(() => {
    setRaisonSocialeWatcher(raisonSocialeWatcher);
  }, [raisonSocialeWatcher]);

  async function formSubmit() {
    try {
      if (getStepToGo() != 0) {
        setStep(getStepToGo());
        setGoToStep(0);
      } else moveToNextStep(index);
    } catch (e) {}
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>commençons par trouver la société</h2>
        <p className='text-sm mb-3'>
          (ex : <span className='line-through span-inherit'>Sarl</span>&nbsp;
          <span className='outfit-semibold span-inherit'>Progest Management</span>). Votre société n'y est pas ? Vous
          souhaitez anonymiser votre estimation ? Saisissez le nom de votre choix.
        </p>
        <Input
          setValue={setValue}
          label={
            <>
              raison sociale <Info onClick={() => showTooltip('Tuto_step_2.jpg')} />
            </>
          }
          register={register}
          preventAutoComplete
          error={errors.raison_sociale ? errors.raison_sociale.message : ''}
          name='raison_sociale'
          onFocus={() => {
            if (step == index + 1) setStep(index);
            setRaisonSocialeWatcher(raisonSocialeWatcher);
          }}
          onBlur={() =>
            setTimeout(() => {
              setRaisonSocialeWatcher('');
            }, 100)
          }
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default TrouverSociete;
