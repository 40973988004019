import React, { Fragment } from 'react';
import './StrongWeakPointsGroupPdf.css';
import Arrow from '../../atoms/icons/pdf/arrow/Arrow';
import H3Pdf from '../h3Pdf/H3Pdf';

// data = {
//   title: '',
//   content: [
//     {
//       title: '',
//       points: [
//         {
//           isStrongPoint: true,
//           content: '',
//         },
//       ],
//     },
//   ],
// };

function StrongWeakPointsGroupPdf({ data, className = '' }) {
  return (
    <div className={`strong-weak-points-group-container-pdf ${className}`}>
      <H3Pdf title={data?.title} shouldAppearOnSummary={0} />
      <div className='strong-weak-points-container-pdf mt-lg'>
        {data?.content.map((pointsGroup, index) => {
          return (
            <Fragment key={index}>
              <h4>{pointsGroup.title}</h4>
              {pointsGroup?.points.map((point, index) => {
                return (
                  <div key={index} className='strong-weak-point-container-pdf'>
                    {point.isStrongPoint ? (
                      <div className='point-title-pdf'>
                        <Arrow color='var(--green)' />
                        Force :
                      </div>
                    ) : (
                      <div key={index} className='point-title-pdf'>
                        <Arrow color='var(--red)' rotation='90deg' />
                        Faiblesse :
                      </div>
                    )}
                    <p className='point-content-pdf'>{point.content}</p>
                  </div>
                );
              })}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default StrongWeakPointsGroupPdf;
