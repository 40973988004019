import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/pdf/twistedArrow.svg';

function TwistedArrow({ width = '22px', color = 'var(--red)', rotation = '0deg', className }) {
  return (
    <Icon
      style={{ '--color': color, 'fill': 'var(--color)', 'transform': `rotateX(${rotation})` }}
      width={width}
      height={width}
      className={className}
    />
  );
}

export default TwistedArrow;
