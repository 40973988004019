import { toggleCollapseElement, toggleCollapseInput } from '../../../../utils/Utils';
import Warning from '../../../atoms/icons/general/warning/Warning';
import './FileInput.css';
import React, { useEffect, useRef } from 'react';

function FileInput({
  label,
  name,
  register,
  error,
  bgColor = 'var(--main-color)',
  color = 'var(--white)',
  hoverColor = 'var(--main-color)',
  borderRadius = '10px',
  padding = '5px 20px',
  onChange,
  onFocus,
  onBlur,
  value,
  disabled,
  icon,
  visible,
  setValue,
  onClear,
  validation,
  accept = '.png, .jpg, .jpeg, .svg',
  buttonText = 'Importer une image',
  removeButton = true,
  removeButtonText = "Retirer l'image",
  className = '',
}) {
  const inputRegister = register && (visible || visible === undefined) ? register(name, validation) : null;

  const ref = useRef(null);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseInput(ref.current, visible);
  }, [visible]);

  function clearInput() {
    setValue(undefined);
    onClear();
  }

  return (
    <>
      <div className='w-100 d-flex' ref={ref}>
        <div
          className={`file-input-container ${className} ${removeButton ? 'with-remove-button' : ''}`}
          style={{
            '--padding': padding,
            '--background-color': bgColor,
            '--color': color,
            '--hover-color': hoverColor,
            '--border-radius': borderRadius,
          }}>
          {label && (
            <label htmlFor='name' className='form-label'>
              {label}
            </label>
          )}
          <label className='file-input-label filled-button' htmlFor={name}>
            {icon}
            {buttonText}
          </label>
          <input
            id={name}
            value={value !== undefined ? value : undefined}
            {...inputRegister}
            type='file'
            className='file-input'
            onChange={e => {
              inputRegister && inputRegister.onChange(e);
              onChange && onChange(e);
            }}
            disabled={(visible !== undefined && !visible) || disabled}
            onFocus={e => onFocus && onFocus(e)}
            onBlur={e => onBlur && onBlur(e)}
            accept={accept}
          />
          {removeButton && (
            <div className='file-input-remove-button' onClick={clearInput}>
              {removeButtonText}
            </div>
          )}
          {error && (
            <div className='form-error'>
              <Warning /> <p>{error}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FileInput;
