import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/listeActivites/equipementDeLaPersonne.svg';

function EquipementDeLaPersonne({ width = '30px', color = 'var(--white)', className }) {
  return (
    <Icon style={{ '--color': color, 'fill': 'var(--color)' }} width={width} height={width} className={className} />
  );
}

export default EquipementDeLaPersonne;
