import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import '../stylesheets/main.css';
import { getScrollbarWidth } from '../utils/Utils';
import AppContext from '../context/AppContext';
import Modal from '../components/organisms/modal/Modal';
import ScrollToTop from '../utils/ScrollToTop';
import Notification from '../components/organisms/notification/Notification';
import MonCompte from '../pages/compte/monCompte/MonCompte';
import ListeActivites from '../pages/estimations/listeActivites/ListeActivites';
import Connexion from '../pages/compte/connexion/Connexion';
import Inscription from '../pages/compte/inscription/Inscription';
import PolitiqueConfidentialite from '../pages/liensLegaux/politiqueConfidentialite/PolitiqueConfidentialite';
import MentionsLegales from '../pages/liensLegaux/mentionsLegales/MentionsLegales';
import Cgu from '../pages/liensLegaux/cgu/Cgu';
import Cgv from '../pages/liensLegaux/cgv/Cgv';
import Loader from '../components/organisms/loader/Loader';
import Header from '../components/organisms/header/Header';
import Footer from '../components/organisms/footer/Footer';
import ErrorPage from '../pages/errors/errorPage/ErrorPage';
import { useWindowSize } from '@uidotdev/usehooks';
import DetailParcours from '../pages/estimations/parcours/detailParcours/DetailParcours';
import { fetchUserDataApi } from '../api/CompteApi';
import Synthese from '../pages/estimations/parcours/synthese/Synthese';
import { CookiesProvider } from 'react-cookie';
import CookieConsentModal from '../components/forms/cookies/cookiesModal/CookieConsentModal';
import ParametrageCookies from '../pages/liensLegaux/parametrageCookie/ParametrageCookies';
import Pdf from '../pages/pdf/Pdf';

function App() {
  const [modalVisible, setModalVisible] = useState(false);
  const [canHideOnClickOutside, setCanHideOnClickOutside] = useState(true);
  const [modalContent, setModalContent] = useState({ title: '', content: <></>, buttons: <></> });
  const [appLoaderVisible, setAppLoaderVisible] = useState(false);
  const [mustAppLoaderShow, setMustAppLoaderShow] = useState(false);
  const createNotificationFunction = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (appLoaderVisible || (!appLoaderVisible && !mustAppLoaderShow)) togglePageScroll();
  }, [appLoaderVisible, mustAppLoaderShow]);

  useEffect(() => {
    document.body.style.setProperty('--scrollbar-width', getScrollbarWidth() + 'px');
  }, [width]);

  useEffect(() => {
    if (appLoaderVisible) {
      setMustAppLoaderShow(true);
      setTimeout(() => {
        setMustAppLoaderShow(false);
      }, 800);
    }
  }, [appLoaderVisible]);

  useEffect(() => {
    if (!modalVisible) {
      setCanHideOnClickOutside(true);
    }
  }, [modalVisible]);

  async function getUserData() {
    try {
      if(!getUserId()) return null;
      const res = (await fetchUserDataApi(getUserId())).data;

      delete res.uuid;
      return res;
    } catch (error) {
      createNotification(
        <>Erreur lors de la récupération des informations utilisateur</>,
        'var(--red)',
        'var(--grey)'
      );
      return null;
    }
  }

  function setUserId(value) {
    if (value) {
      localStorage.setItem('uuid', value);
      getUserData();
    } else localStorage.removeItem('uuid');
  }

  function getUserId() {
    return localStorage.getItem('uuid');
  }

  function togglePageScroll() {
    if (appLoaderVisible) {
      document.body.classList.add('loading-page');
    } else {
      document.body.classList.remove('loading-page');
    }
  }

  function toggleBodyScroll(enable) {
    if (enable) document.body.classList.remove('non-scrollable-body');
    else document.body.classList.add('non-scrollable-body');
  }

  function createNotification(content, bgColor = 'var(--main-color)', color = 'var(--white)', visibleTime = 5000) {
    if (createNotificationFunction.current) createNotificationFunction.current(content, bgColor, color, visibleTime);
  }

  return (
    <CookiesProvider>
      <AppContext.Provider
        value={{
          setModalContent,
          setModalVisible,
          setCanHideOnClickOutside,
          createNotification,
          setAppLoaderVisible,
          toggleBodyScroll,
          getUserId,
          getUserData,
          setUserId,
        }}>
        <Modal
          visible={modalVisible}
          setVisible={setModalVisible}
          title={modalContent.title}
          canHideOnClickOutside={canHideOnClickOutside}>
          {modalContent.content}
          {modalContent.buttons && <div className='modal-buttons'>{modalContent.buttons}</div>}
        </Modal>
        <Notification createNotificationFunction={createNotificationFunction} />
        <Loader visible={appLoaderVisible || mustAppLoaderShow} className='app-loader' />
        <HelmetProvider>
          <Router>
            <CookieConsentModal />
            <Header />
            <ScrollToTop />
            <Routes>
              {/*Autres */}
              <Route path='/liste-activites' element={<ListeActivites />} />
              <Route path='/pdf/:estimationIdParam' element={<Pdf />} />

              {/* Liens légaux */}
              <Route path='/politique-confidentialite' element={<PolitiqueConfidentialite />} />
              <Route path='/mentions-legales' element={<MentionsLegales />} />
              <Route path='/parametrage-cookies' element={<ParametrageCookies />} />
              <Route path='/cgu' element={<Cgu />} />
              <Route path='/cgv' element={<Cgv />} />

              {/* Estimations */}
              <Route path='/synthese/:estimationIdParam' element={<Synthese />} />
              <Route path='/detail-parcours/:estimationTypeParam/:estimationIdParam' element={<DetailParcours />} />
              <Route path='/detail-parcours/:estimationTypeParam' element={<DetailParcours />} />

              {/* Compte */}
              <Route path='/' element={<Connexion />} />
              <Route path='/mon-compte/:tabParam' element={<MonCompte />} />
              <Route path='/connexion' element={<Connexion />} />
              {/* <Route path='/inscription' element={<Inscription />} /> */}

              {/* Errors */}
              <Route path='/301' element={<ErrorPage code={301} />} />
              <Route path='/302' element={<ErrorPage code={302} />} />
              <Route path='/401' element={<ErrorPage code={401} />} />
              <Route path='/402' element={<ErrorPage code={402} />} />
              <Route path='/403' element={<ErrorPage code={403} />} />
              <Route path='/404' element={<ErrorPage code={404} />} />
              <Route path='/500' element={<ErrorPage code={500} />} />
              <Route path='/503' element={<ErrorPage code={503} />} />
              <Route path='/504' element={<ErrorPage code={504} />} />

              <Route path='*' element={<Navigate to='/404' replace />} />
            </Routes>
            <Footer />
          </Router>
        </HelmetProvider>
      </AppContext.Provider>
    </CookiesProvider>
  );
}

export default App;
