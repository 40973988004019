import axiosInstance from './AxiosConfig';

export const registerUserApi = async values => axiosInstance.post('/user/add', values);

export const loginUserApi = async values => axiosInstance.post('/auth/login', values);

export const logoutUserApi = async () => axiosInstance.post('/auth/logout');

export const fetchUserDataApi = async userId => axiosInstance.get(`/user/get?method=uuid&uuid=${userId}`);

// Get user types
export const fetchUserDropdownApi = async dropdown => axiosInstance.get(`/user/dropdown?field=${dropdown}`);

// Account creation
export const validateAccountApi = async (userId, code) =>
  axiosInstance.post('/user/activate-account', { uuid: userId, code: code });

export const refreshAccountCodeApi = async userId => axiosInstance.post('/user/refresh-account-code', { uuid: userId });

// Password reset
export const requestEditPasswordApi = async email => axiosInstance.post('/user/request-edit-password', { email });

export const resetPasswordApi = async (email, password) =>
  axiosInstance.post('/user/reset-password', { email, password });

export const checkPasswordCodeApi = async (email, code) =>
  axiosInstance.post('/user/check-password-code', { email, code });
