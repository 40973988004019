import axios from 'axios';
import axiosInstance from './AxiosConfig';

export const fetchToken = async () => axiosInstance.get('/parcours/siren/token');

export const fetchAccessToken = async token =>
  axios.post('https://api.insee.fr/token?grant_type=client_credentials', null, {
    headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' },
  });

export const fetchSirenData = async (raisonSociale, accessToken) =>
  axios.get(`https://api.insee.fr/entreprises/sirene/V3/siret?q=denominationUniteLegale:"${raisonSociale}"~2`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
