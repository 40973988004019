import React from 'react';
import './ParametrageCookieForm.css';
import FilledButton from '../../../molecules/buttons/filledButton/FilledButton';
import { useForm, Controller } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import TextHr from '../../../molecules/textHr/TextHr';
import Radio from '../../../molecules/formComponents/radio/Radio';

function ParametrageCookiesForm() {
    const { handleSubmit, control } = useForm();
    const [, setCookie] = useCookies([
      'preference-cookies-categories',
      'cookie-performance',
      'cookie-google-analytics',
      'cookie-linkedin',
      'cookie-facebook',
      'cookie-instagram',
      'cookie-pinterest',
      'cookie-youtube',
    ]);

    function onSubmit(data) {
      const cookieSettings = [
        { key: 'preference-cookies-categories', default: 'accepted' },
        { key: 'cookies-performance', default: 'accepted' },
        { key: 'cookie-google-analytics', default: 'accepted' },
        { key: 'cookie-linkedin', default: 'accepted' },
        { key: 'cookie-facebook', default: 'accepted' },
        { key: 'cookie-instagram', default: 'accepted' },
        { key: 'cookie-pinterest', default: 'accepted' },
        { key: 'cookie-youtube', default: 'accepted' },
      ];
    
      cookieSettings.forEach(({ key, default: defaultValue }) => {
        const value = data[key] === 1 ? 'denied' : defaultValue;
        setCookie(key.replace('cookies-', 'cookie-'), value, { path: '/' });
      });
    
      window.location.href = '/';
    }
    
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='big-text-container mt-lg'>
          <p>
            Les cookies catégorisés comme nécessaires sont essentiels aux fonctionnalités de base du site. Nous
            utilisons également des cookies tiers, qui nous aident à analyser et à comprendre votre utilisation de ce
            site, afin d’améliorer continuellement votre expérience de navigation. Ces cookies ne seront stockés dans
            votre navigateur qu'avec votre consentement. Vous avez également la possibilité de désactiver ces cookies.
            Toutefois, la désactivation de certains de ces cookies peut avoir une incidence sur votre expérience de
            navigation.
          </p>
          <p>
            Cette page vous permet de donner, de refuser ou de retirer votre consentement à tout moment, soit
            globalement soit catégorie par catégorie.
          </p>
          <p>
            Consulter notre{' '}
            <Link className='link' to='/politique-confidentialite'>
              politique de confidentialité.
            </Link>
          </p>
          <TextHr className='my-xl'>Cookies nécessaires (toujours activé)</TextHr>
          <p>
            Ces cookies ont pour objectif de fluidifier votre navigation sur le Site et de permettre l’utilisation
            optimale de leurs différentes fonctionnalités. Ils sont essentiels pour que le Site ainsi que les
            fonctionnalités que la société Galaxico y propose fonctionnent correctement. Ces cookies permettent au Site
            de fonctionner de manière optimale pour votre confort d’utilisation.
          </p>
          <hr className='hr-cookie' />
          <div className='container necessary-cookies'>
            <div className='text-necessary-cookies'>
              <p className='text-lg outfit-bold'>Préférence pour toutes les catégories</p>
            </div>
            <div className='radio-group-necessary-cookies'>
              <Controller
                  name='preference-cookies-categories'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                      <Radio
                      direction='row'
                      name='preference-cookies-categories'
                      defaultChecked={0}
                      content={[
                          {
                          label: 'Autoriser',
                          value: 0,
                          },
                          {
                          label: 'Refuser',
                          value: 1,
                          },
                      ]}
                      />
                  )}
              />
            </div>
          </div>
          <TextHr className='mb-xl mt-lg'>Cookies de performance</TextHr>
          <p>
            Les cookies de performance ont pour objectif de rendre le fonctionnement du Site plus convivial et vous
            offrir une expérience en ligne plus personnalisée. Vous pouvez vous y opposer et les supprimer, mais la
            société Galaxico attire votre attention sur le fait que votre expérience utilisateur risque d’en être
            dégradée.
          </p>
          <div className='container necessary-cookies'>
            <div className='text-necessary-cookies'>
              <p className='text-lg outfit-bold'>Cookies de performance</p>
            </div>
            <div className='radio-group-necessary-cookies'>
              <Controller
                  name='cookies-performance'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                      <Radio
                      direction='row'
                      name='cookies-performance'
                      defaultChecked={0}
                      content={[
                          {
                          label: 'Autoriser',
                          value: 0,
                          },
                          {
                          label: 'Refuser',
                          value: 1,
                          },
                      ]}
                      />
                  )}
              />
            </div>
          </div>

          <TextHr className='mb-xl mt-lg'>Mesure d'audience</TextHr>
          <p>
            La société Galaxico utilise des cookies de mesure d’audience pour analyser l’utilisation qui est faite du
            Site afin d’en améliorer le fonctionnement. Ces cookies permettent notamment de recenser le nombre de
            visiteurs, d’analyser les pages les plus visitées, d’enregistrer la façon dont vous utilisez le Site et ce
            afin d’améliorer sa performance et son contenu et d’améliorer votre expérience de navigation.
          </p>
          <div className='container necessary-cookies'>
            <div className='text-necessary-cookies'>
              <p className='text-lg outfit-bold'>Google Analytics</p>
              <p className='text-mb'>
                Lien vers la{' '}
                <Link className='link' to='/politique-confidentialite'>
                  politique de confidentialité
                </Link>
              </p>
            </div>
            <div className='radio-group-necessary-cookies'>
              <Controller 
                  name='cookie-google-analytics'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                      <Radio
                      direction='row'
                      name='cookie-google-analytics'
                      defaultChecked={0}
                      content={[
                          {
                          label: 'Autoriser',
                          value: 0,
                          },
                          {
                          label: 'Refuser',
                          value: 1,
                          },
                      ]}
                      />
                  )}
              />
            </div>
          </div>

          <TextHr className='my-xl'>Contenu multimédia (vidéos) et réseaux sociaux</TextHr>
          <p>
            Le Site contient certaines fonctionnalités visant à améliorer son interactivité et s’appuyant sur des
            services proposés par des sites tiers. Il s’agit notamment :
          </p>
          <ul className='dots-list'>
            <li>Des boutons de partage vers les réseaux sociaux (LinkedIn, Facebook, Instagram et Pinterest);</li>
            <li>Des vidéos diffusées sur le Site (YouTube).</li>
          </ul>
          <p>
            Si vous donnez votre consentement à l’utilisation de ces fonctionnalités, ces tiers (LinkedIn, Facebook,
            Instagram, Pinterest et YouTube) déposeront des cookies qui vous permettront de visualiser directement sur
            le Site du contenu hébergé par ces tiers ou de partager nos contenus sur le site de ces tiers.
          </p>
          <p>
            Via ces cookies, ces tiers collecteront et utiliseront vos données de navigation pour des finalités qui leur
            sont propres, conformément à leur politique de confidentialité. Nous vous invitons à consulter les
            politiques de confidentialité de ces tiers (en cliquant sur les liens ci-dessous) pour connaitre les
            conditions dans lesquelles ils traitent les données personnelles vous concernant recueillies lors de votre
            utilisation de leurs services.
          </p>
          <p>
            Pour exercer vos droits sur les données collectées par ces tiers ou pour toute question sur ces traitements, 
            vous pouvez les contacter directement.
          </p>
          <div className='container necessary-cookies'>
            <div className='text-necessary-cookies'>
              <p className='text-lg outfit-bold'>LinkedIn</p>
              <p className='text-mb'>
                Lien vers la{' '}
                <Link className='link' to='/politique-confidentialite'>
                  politique de confidentialité
                </Link>
              </p>
            </div>
            <div className='radio-group-necessary-cookies'>
              <Controller
                  name='cookie-linkedin'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                      <Radio
                      direction='row'
                      name='cookie-linkedin'
                      defaultChecked={0}
                      content={[
                          {
                          label: 'Autoriser',
                          value: 0,
                          },
                          {
                          label: 'Refuser',
                          value: 1,
                          },
                      ]}
                      />
                  )}
              />
            </div>
          </div>
          <div className='container necessary-cookies'>
            <div className='text-necessary-cookies'>
              <p className='text-lg outfit-bold'>Facebook</p>
              <p className='text-mb'>
                Lien vers la{' '}
                <Link className='link' to='/politique-confidentialite'>
                  politique de confidentialité
                </Link>
              </p>
            </div>
            <div className='radio-group-necessary-cookies'>
              <Controller
                  name='cookie-facebook'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                      <Radio
                      direction='row'
                      name='cookie-facebook'
                      defaultChecked={0}
                      content={[
                          {
                          label: 'Autoriser',
                          value: 0,
                          },
                          {
                          label: 'Refuser',
                          value: 1,
                          },
                      ]}
                      />
                  )}
              />
            </div>
          </div>
          <div className='container necessary-cookies'>
            <div className='text-necessary-cookies'>
              <p className='text-lg outfit-bold'>Instagram</p>
              <p className='text-mb'>
                Lien vers la{' '}
                <Link className='link' to='/politique-confidentialite'>
                  politique de confidentialité
                </Link>
              </p>
            </div>
            <div className='radio-group-necessary-cookies'>
              <Controller
                  name='cookie-instagram'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                      <Radio
                      direction='row'
                      name='cookie-instagram'
                      defaultChecked={0}
                      content={[
                          {
                          label: 'Autoriser',
                          value: 0,
                          },
                          {
                          label: 'Refuser',
                          value: 1,
                          },
                      ]}
                      />
                  )}
              />
            </div>
          </div>
          <div className='container necessary-cookies'>
            <div className='text-necessary-cookies'>
              <p className='text-lg outfit-bold'>Pinterest</p>
              <p className='text-mb'>
                Lien vers la{' '}
                <Link className='link' to='/politique-confidentialite'>
                  politique de confidentialité
                </Link>
              </p>
            </div>
            <div className='radio-group-necessary-cookies'>
              <Controller
                  name='cookie-pinterest'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                      <Radio
                      direction='row'
                      name='cookie-pinterest'
                      defaultChecked={0}
                      content={[
                          {
                          label: 'Autoriser',
                          value: 0,
                          },
                          {
                          label: 'Refuser',
                          value: 1,
                          },
                      ]}
                      />
                  )}
              />
            </div>
          </div>
          <div className='container necessary-cookies'>
            <div className='text-necessary-cookies'>
              <p className='text-lg outfit-bold'>Youtube</p>
              <p className='text-mb'>
                Lien vers la{' '}
                <Link className='link' to='/politique-confidentialite'>
                  politique de confidentialité
                </Link>
              </p>
            </div>
            <div className='radio-group-necessary-cookies'>
              <Controller
                  name='cookie-youtube'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                      <Radio
                      direction='row'
                      name='cookie-youtube'
                      defaultChecked={0}
                      content={[
                          {
                          label: 'Autoriser',
                          value: 0,
                          },
                          {
                          label: 'Refuser',
                          value: 1,
                          },
                      ]}
                      />
                  )}
              />
            </div>
          </div>
          <hr className='hr-cookie' />
          <FilledButton type='submit' className='validate-form-cookie-button'>
            Valider et revenir sur la page d'accueil
          </FilledButton>
        </div>
    </form>
  );
};

export default ParametrageCookiesForm;