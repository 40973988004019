import React from 'react';
import FullLogo from '../../../../assets/logos/logo-cap-cession-eme.png';
import { ReactComponent as IconLogo } from '../../../../assets/logos/logo-emc-icon.svg';

//typeLogo = 1 : full / 2 : icon
function EmeLogo({ dark, className, typeLogo = 1, height = '85px' }) {
  return typeLogo === 1 ? (
    <img src={FullLogo} className='className' style={{height: height}} alt='logo' />
  ) : (
    <IconLogo style={{ fill: dark ? 'var(--grey)' : 'var(--white)', height: height }} className={className} />
  );
}

export default EmeLogo;
