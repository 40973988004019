import AppContext from '../../../../context/AppContext';
import './CodeInput.css';
import React, { useContext, useEffect, useRef, useState } from 'react';

function CodeInput({ errorInfos, sendCodeFunction, inputsValues, setInputsValues, disabled = false }) {
  const inputsRefs = useRef(new Array(6).fill(null));
  const [canSendCode, setCanSendCode] = useState(1); // 0  = code envoyé, 1 = peut envoyer un code, 2 = envoi en cours, 3 = erreur
  const { createNotification } = useContext(AppContext);

  useEffect(() => {
    inputsRefs.current[0]?.focus();
  }, []);

  function handleInputChange(e, index) {
    const newValues = e.target.value.replace(/\D/g, '');
    const oldValues = [...inputsValues];

    for (let i = index; i < Math.min(6, index + newValues.length); i++) {
      oldValues[i] = newValues[i - index];
    }

    inputsRefs.current[Math.min(6, index + newValues.length)]?.focus();
    setInputsValues(oldValues);
  }

  function handleBackspace(e, index) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') return;

    const oldValues = [...inputsValues];
    oldValues[index] = '';
    setInputsValues(oldValues);
    inputsRefs.current[Math.max(0, index - 1)]?.focus();
  }

  function getCodeStatus() {
    switch (canSendCode) {
      case 0:
        return <p className='confirm-inscription-code-sent'>Code envoyé</p>;
      case 1:
        return (
          <p className='confirm-inscription-send-code' onClick={sendCode}>
            Renvoyer un code par email
          </p>
        );
      case 2:
        return <p className='confirm-inscription-code-sent'>Envoi en cours</p>;
      case 3:
        return <p className='confirm-inscription-code-sent text-red'>Une erreur est survenue</p>;
    }
  }

  async function sendCode() {
    try {
      setCanSendCode(2);
      await sendCodeFunction();
      setCanSendCode(0);
      setTimeout(() => {
        setCanSendCode(1);
      }, 5000);
    } catch (error) {
      setCanSendCode(3);
      createNotification(<>Une erreur est survenue, veuillez réessayer plus tard</>, 'var(--red)', 'var(--grey');
    }
  }

  return (
    <>
      <div className={`code-input-container ${errorInfos.error_code ? 'with-error' : ''}`}>
        {inputsValues.map((value, index) => {
          return (
            <div className='field-container' key={index}>
              <input
                disabled={disabled}
                name={`code_${index}`}
                autoComplete='off'
                ref={ref => (inputsRefs.current[index] = ref)}
                value={inputsValues[index]}
                onKeyDown={e => handleBackspace(e, index)}
                onChange={e => handleInputChange(e, index)}
              />
            </div>
          );
        })}
      </div>

      <div className='code-input-subtexts'>
        {getCodeStatus()}
        {errorInfos.current_attempt > 0 && (
          <p>
            {errorInfos.max_attempts - errorInfos.current_attempt} essai
            {errorInfos.max_attempts - errorInfos.current_attempt && 's'} restants
          </p>
        )}
      </div>
    </>
  );
}

export default CodeInput;
