import { Link } from 'react-router-dom';
import './Breadcrumbs.css';
import React from 'react';

// routes = [
//     {
//         name: '',
//         to: '',
//     },
//     {
//         name: '',
//         to: '',
//     },
// ];

function Breadcrumbs({ routes, className = '' }) {
  return (
    <div className={'breadcrumbs-container ' + className}>
      {routes
        .map((route, key) => {
          return (
            <Link key={key} to={route.to}>
              {route.name}
            </Link>
          );
        })
        .reduce((prev, curr) => [prev, ' > ', curr])}
    </div>
  );
}

export default Breadcrumbs;
