import React, { useState, useRef, useEffect } from 'react';
import './FileInputPreview.css';
import Warning from '../../../atoms/icons/general/warning/Warning';
import { toggleCollapseInput } from '../../../../utils/Utils';
import Trash from '../../../atoms/icons/general/trash/Trash';
import Pen from '../../../atoms/icons/monCompte/pen/Pen';
import { checkImage, getImageInfos } from '../../../../utils/Utils';
import { deleteEstimationImageApi } from '../../../../api/EstimationApi';

function FileInputPreview({
  label,
  name,
  register,
  bgColor = 'var(--main-color)',
  color = 'var(--white)',
  hoverColor = 'var(--main-color)',
  borderRadius = '10px',
  padding = '5px 20px',
  onChange,
  onFocus,
  onBlur,
  value,
  disabled,
  icon,
  visible,
  setValue,
  onClear = () => {},
  validation,
  accept = '.png, .jpg, .jpeg, .svg',
  buttonText = 'Importer une image',
  removeButton = true,
  removeButtonText = "Retirer l'image",
  className = '',
}) {
  const [image, setImage] = useState(value);
  const [imgErrorMessage, setImgErrorMessage] = useState('');
  const inputRef = useRef(null);

  const inputRegister = register && (visible || visible === undefined) ? register(name, validation) : null;

  useEffect(() => {
    if (visible !== undefined) toggleCollapseInput(inputRef.current, visible);
    if (value) {
      setImage(value);
    }
  }, [visible, value]);

  async function handleFiles(file) {
    const errorMsg = checkImage(file);
    if (!errorMsg) {
      try {
        const imageInfos = await getImageInfos({ target: { files: [file] } });
        setImage(imageInfos.data);
        setImgErrorMessage('');
        if (onChange) onChange(file);
      } catch (error) {
        setImgErrorMessage('Erreur lors de la lecture de l\'image');
      }
    } else {
      setImgErrorMessage(errorMsg);
    }
  };

  function handleChange(e) {
    handleFiles(e.target.files[0]);
    if (inputRegister) inputRegister.onChange(e);
  };

  function deletePreview(e) {
    setImage(null);
    setImgErrorMessage('');
    onClear(e, name);
  }

  return (
    <div className={`file-input-preview-container ${className}`} ref={inputRef}
      style={{
        '--padding': padding,
        '--background-color': bgColor,
        '--color': color,
        '--hover-color': hoverColor,
        '--border-radius': borderRadius,
      }}
    >
      {label && <label className='form-label'>{label}</label>}
      <div className='file-input-preview-content'>
        {image ? (
          <div className='file-input-preview'>
            <img src={image} alt={`preview-${name}`} />
            <button type='button' className='file-input-preview-edit' onClick={() => handleFiles(image)}>
              <Pen color='white' className='edit-img-pen'/>
            </button>
            <button type='button' className='file-input-preview-remove' onClick={deletePreview}>
              <Trash color='white' className='remove-img-trash'/>
            </button>
          </div>
        ) : (
          <>
            <label className='file-input-preview-label filled-button' htmlFor={name}>
              {icon}
              {buttonText}
            </label>
            <input
              id={name}
              {...inputRegister}
              type='file'
              className='file-input-img'
              onChange={handleChange}
              disabled={(visible !== undefined && !visible) || disabled}
              onFocus={onFocus}
              onBlur={onBlur}
              accept={accept}
            />
          </>
        )}
      </div>
      {imgErrorMessage && (
        <div className='form-error'>
          <Warning /> <p>{imgErrorMessage}</p>
        </div>
      )}
    </div>
  );
}

export default FileInputPreview;