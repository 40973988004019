import { generateHrefPdf } from '../summaryPdf/SummaryPdf';
import './H1Pdf.css';
import React from 'react';

function H1Pdf({ title, className = '', fontSize = '24px', shouldAppearOnSummary = 1 }) {
  return (
    <div id={generateHrefPdf(title)} className={`text-h1-container ${className}`}>
      <h2 custom-visibility={shouldAppearOnSummary} style={{ fontSize: fontSize }}>
        {title}
      </h2>
    </div>
  );
}

export default H1Pdf;
