import React from 'react';
import './BannerPdf.css';
import { generateHrefPdf } from '../summaryPdf/SummaryPdf';

function BannerPdf({ title, className = '', shouldAppearOnSummary = 1 }) {
  return (
    <div id={generateHrefPdf(title)} className={`banner-pdf-container ${className}`}>
      <h2 custom-visibility={shouldAppearOnSummary}>{title}</h2>
    </div>
  );
}

export default BannerPdf;
