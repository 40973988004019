import './Toggle.css';
import React, { useRef, useEffect } from 'react';
import { toggleCollapseElement } from '../../../../utils/Utils';
import Warning from '../../../atoms/icons/general/warning/Warning';

function Toggle({
  label,
  label1,
  name,
  register,
  visible,
  color = 'var(--secondary-color)',
  bgColor = 'var(--main-color)',
  onChange,
  checked,
  className = '',
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (visible !== undefined) toggleCollapseElement(ref.current, visible);
  }, [visible]);

  const inputRegister = register ? register(name) : null;

  return (
    <div className={`toggle-error-container ${className}`} ref={ref}>
      <div className='field-container'>
        <div className='toggle-container'>
          <label className='form-label' htmlFor={name}>
            {label}
          </label>
          <input
            disabled={visible !== undefined && !visible}
            checked={checked}
            name={name}
            className='toggle-input'
            type='checkbox'
            id={name}
            {...inputRegister}
            onChange={e => {
              register && inputRegister.onChange(e);
              onChange && onChange(e);
            }}
          />
          <label htmlFor={name} className='toggle-front' style={{ '--bg-color': bgColor, '--color': color }} />
          <label className='form-label' htmlFor={name}>
            {label1}
          </label>
        </div>
      </div>
    </div>
  );
}

export default Toggle;
