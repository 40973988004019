import React, { useContext, useEffect, useRef, useState } from 'react';
import Input from '../../../../molecules/formComponents/input/Input';
import { emailValidation, passwordValidation } from '../../../../../utils/formValidation/FormValidation';
import FilledButton from '../../../../molecules/buttons/filledButton/FilledButton';
import AppContext from '../../../../../context/AppContext';
import { toggleCollapseElement } from '../../../../../utils/Utils';
import { checkPasswordCodeApi, requestEditPasswordApi, resetPasswordApi } from '../../../../../api/CompteApi';
import CodeInput from '../../../../molecules/formComponents/codeInput/CodeInput';
import './ForgotPasswordModal.css';
import PasswordInput from '../../../../molecules/formComponents/passwordInput/PasswordInput';

function ForgotPasswordModal({ email }) {
  const [values, setValues] = useState({
    email: '',
    code: ['', '', '', '', '', ''],
    password: '',
    password_confirm: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    password_confirm: '',
    code: {
      current_attempt: 0,
      error_code: '',
      max_attempts: 3,
    },
  });
  const [shouldValidateInput, setShouldValidateInput] = useState({ email: false, password: false });
  const [step, setStep] = useState(0);
  const { createNotification, setModalVisible, setModalContent } = useContext(AppContext);
  const formsRefs = useRef(new Array(2).fill(null));

  useEffect(() => {
    showForms();
  }, [step]);

  useEffect(() => {
    setValues({ ...values, email: email });
  }, [email]);

  useEffect(() => {
    submitCode();
  }, [values.code]);

  useEffect(() => {
    validatePassword();
  }, [values.password, values.password_confirm]);

  useEffect(() => {
    validateEmail();
  }, [values.email]);

  function showForms() {
    if (!formsRefs.current[0] || !formsRefs.current[1]) return setTimeout(showForms, 100);
    switch (step) {
      case 0:
        toggleCollapseElement(formsRefs.current[1], false);
        break;
      case 1:
        toggleCollapseElement(formsRefs.current[0].children[0], false);
        toggleCollapseElement(formsRefs.current[0].children[2], false);
        toggleCollapseElement(formsRefs.current[1], true);
        break;
      case 2:
        toggleCollapseElement(formsRefs.current[1].children[0], false);
        toggleCollapseElement(formsRefs.current[1].children[2], false);
        toggleCollapseElement(formsRefs.current[1], true);
        break;
    }
  }

  async function submitEmail(e) {
    e.preventDefault();
    setShouldValidateInput({ ...shouldValidateInput, email: true });
    if (!validateEmail(true)) return;

    try {
      await requestEditPasswordApi(values.email);
      setStep(1);
    } catch (error) {
      createNotification(<>Une erreur est survenue, veuillez réessayer plus tard</>, 'var(--red)', 'var(--grey');
    }
  }

  async function submitCode() {
    const code = values.code.join('');
    if (code.length !== 6) return;
    try {
      await checkPasswordCodeApi(values.email, code);
      setErrors({
        ...errors,
        code: {
          current_attempt: 0,
          error_code: '',
          max_attempts: 3,
        },
      });
      setStep(2);
    } catch (error) {
      setErrors({ ...errors, code: error.response.data.infos });
    }
  }

  async function submitPassword(e) {
    e.preventDefault();
    setShouldValidateInput({ ...shouldValidateInput, password: true });
    if (!validatePassword(true)) return;
    try {
      await resetPasswordApi(values.email, values.password);
      setModalVisible(false);
      setModalContent({ title: '', content: <></> });
      createNotification('Votre mot de passe a été modifié avec succès');
    } catch (error) {
      createNotification(<>Une erreur est survenue, veuillez réessayer plus tard</>, 'var(--red)', 'var(--grey');
    }
  }

  function validateEmail(force) {
    if (!shouldValidateInput.email && !force) return true;
    if (values.email === '') {
      setErrors({ ...errors, email: 'Ce champ est requis' });
      return false;
    }
    if (!values.email.match(emailValidation.pattern.value)) {
      setErrors({ ...errors, email: 'Adresse mail invalide' });
      return false;
    }
    setErrors({ ...errors, email: '' });
    return true;
  }

  function validatePassword(force) {
    if (!shouldValidateInput.password && !force) return true;

    if (values.password.length < 1) {
      setErrors({ ...errors, password: 'Ce champ est requis' });
      return false;
    }
    if (!values.password.match(passwordValidation.pattern.value)) {
      setErrors({ ...errors, password: 'Votre mot de passe ne respecte pas les critères de sécurité' });
      return false;
    }
    setErrors({ ...errors, password: '' });
    if (values.password !== values.password_confirm) {
      setErrors({ ...errors, password: '', password_confirm: 'Les mots de passe ne correspondent pas' });
      return false;
    }

    setErrors({ ...errors, password: '', password_confirm: '' });
    return true;
  }

  async function sendCode() {
    await requestEditPasswordApi(values.email);
  }

  function getErrorMessage(errorCode) {
    switch (errorCode) {
      case '01':
      case '04':
        return 'Code expiré. Veuillez en demander un nouveau.';
      case '02':
        return 'Trop de tentatives. Veuillez demander un nouveau code.';
      case '03':
        return 'Code saisi invalide.';
      default:
        return '';
    }
  }

  return (
    <div className='forgot-password-container'>
      <form onSubmit={submitEmail} ref={ref => (formsRefs.current[0] = ref)}>
        <p className='centered with-transition'>
          Veuillez saisir votre adresse mail pour recevoir un lien de réinitialisation de votre mot de passe.
        </p>
        <Input
          value={values.email}
          onChange={e => setValues({ ...values, email: e.target.value })}
          name='email'
          className='with-transition'
          error={errors.email}
          disabled={step !== 0}
        />
        <div className='centered'>
          <FilledButton type='submit'>Envoyer</FilledButton>
        </div>
      </form>
      <form
        onSubmit={submitCode}
        ref={ref => (formsRefs.current[1] = ref)}
        className={`with-transition ${step === 1 ? 'forgot-password-container-auto' : ''}`}>
        <p className='centered'>Veuillez saisir le code à 6 chiffres que nous avons envoyé à l'adresse renseignée</p>
        {errors.code.error_code && (
          <p className='confirm-inscription-error'>{getErrorMessage(errors.code.error_code)}</p>
        )}
        <CodeInput
          disabled={step !== 1}
          inputsValues={values.code}
          setInputsValues={value => setValues({ ...values, code: value })}
          sendCodeFunction={sendCode}
          errorInfos={errors.code}
        />
      </form>
      <form onSubmit={submitPassword} className={`with-transition ${step > 1 ? '' : 'd-none'}`}>
        <p className='centered'>Veuillez saisir votre nouveau mot de passe</p>
        <PasswordInput
          value={values.password}
          onChange={e => setValues({ ...values, password: e.target.value })}
          name='password'
          type='password'
          error={errors.password}
          showTooltip
        />
        <p className='centered'>Confirmez votre nouveau mot de passe</p>
        <PasswordInput
          password_confirm
          value={values.password_confirm}
          error={errors.password_confirm}
          onChange={e => setValues({ ...values, password_confirm: e.target.value })}
          name='password_confirm'
          type='password'
        />
        <div className='centered'>
          <FilledButton type='submit'>Envoyer</FilledButton>
        </div>
      </form>
    </div>
  );
}

export default ForgotPasswordModal;
