import React from 'react';

function InfosBlock({ infos }) {
  return (
    <div className='mt-3xl'>
      <p style={{ fontWeight: 'bold', fontSize: '30px' }}>{infos.name}</p>
      <p style={{ fontWeight: 'bold', fontSize: '24px' }}>{infos.type}</p>
      <p className='mt-sm text-lg'>{infos.address}</p>
      <p className='mt-lg text-lg'>Siret : {infos.siret}</p>
      {/* <p className='text-lg'>Code APE : infos.apeCode</p> */}
      {/* <p className='text-lg'>Représentant légal : infos.legalRepresentative</p> */}
      <p className='text-lg'>Date de création : {infos.creationDate}</p>
      {/* <p className='text-lg'>Capital social : {infos.capital}</p> */}
    </div>
  );
};

export default InfosBlock;