import './ListeMaterielPdf.css';
import React, { Fragment } from 'react';

// data = [
//     {
//         id: 1,
//         name: "",
//         description: ""
//     },
// ]

function ListeMaterielPdf({ data }) {
  return (
    <table className='liste-materiel-container'>
      <tbody>
        {data?.map(row => (
          <tr key={row.id}>
            <th>
              {row.name.split(' ').map((intitule, key) => (
                <Fragment key={key}>
                  {key > 0 && <>&nbsp;</>}
                  {intitule}
                </Fragment>
              ))}
            </th>
            <td>{row.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ListeMaterielPdf;
