import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/pdf/arrow.svg';

function Arrow({ width = '22px', color = 'var(--white)', rotation = '0deg', className }) {
  return (
    <Icon style={{ '--color': color, fill: 'var(--color)', rotate: rotation }} width={width} height={width} className={className} />
  );
}

export default Arrow;
