import TwistedArrow from '../../atoms/icons/pdf/twistedArrow/TwistedArrow';
import EstimationsRangePdf from '../estimationsRangePdf/EstimationsRangePdf';
import HrPdf from '../hrPdf/HrPdf';
import './EstimationDetailsPdf.css';
import React, { Fragment } from 'react';

// data = [
//   {
//     title: '',
//     subtitle: '',
//     lowAmount: '',
//     midAmount: '',
//     highAmount: '',
//   } X 3
// ];

function EstimationDetailsPdf({ data, className = '' }) {

  function extractData(data) {
    return {
      hasTopArrows: false,
      values: [
        {
          estimation: data.lowAmount,
          description: (
            <>
              <TwistedArrow width='15px' />
              BASSE
            </>
          ),
        },
        { estimation: data.midAmount, description: <>MOYENNE</> },
        {
          estimation: data.highAmount,
          description: (
            <>
              HAUTE
              <TwistedArrow color='var(--green)' rotation='180deg' width='15px' />
            </>
          ),
        },
      ],
    };
  }

  return (
    <div className={`estimation-details-container-pdf ${className}`}>
      {data.map((detail, index) => (
        <Fragment  key={index}>
          <div>
            <p className='estimation-details-title'>{detail.title}</p>
            <p className='estimation-details-subtitle'>{detail.subtitle}</p>
            <EstimationsRangePdf data={extractData(detail)} className='value-estimation-details' />
          </div>
          {index < data.length - 1 && <HrPdf className="mt-0" />}
        </Fragment>
      ))}
    </div>
  );
}

export default EstimationDetailsPdf;
