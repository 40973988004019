import { useState, useEffect, useContext, Fragment, useRef } from 'react';
import BannerPdf from '../../components/pdfComponents/bannerPdf/BannerPdf';
import PagePdf from '../../components/pdfComponents/pagePdf/PagePdf';
import TablePdf from '../../components/pdfComponents/tablePdf/TablePdf';
import H3Pdf from '../../components/pdfComponents/h3Pdf/H3Pdf';
import StrongWeakPointsGroupPdf from '../../components/pdfComponents/strongWeakPointsGroupPdf/StrongWeakPointsGroupPdf';
import './Pdf.css';
import React from 'react';
import SummaryPdf, { generateSummaryChapters } from '../../components/pdfComponents/summaryPdf/SummaryPdf';
import CirclePercentagePdf from '../../components/pdfComponents/circlePercentagePdf/CirclePercentagePdf';
import CalculationDetailsPdf from '../../components/pdfComponents/calculationDetailsPdf/CalculationDetailsPdf';
import EstimationsRangePdf from '../../components/pdfComponents/estimationsRangePdf/EstimationsRangePdf';
import EstimationDetailsPdf from '../../components/pdfComponents/estimationDetailsPdf/EstimationDetailsPdf';
import BarChart from '../../components/pdfComponents/chartPdf/BarChart';
import DoughnutChart from '../../components/pdfComponents/chartPdf/DoughnutChart';
import H1Pdf from '../../components/pdfComponents/h1Pdf/H1Pdf';
import VisitingCard from '../../components/organisms/visitingCard/VisitingCard';
import HrPdf from '../../components/pdfComponents/hrPdf/HrPdf';
import logoUrl from '../../assets/logos/logo-cap-cession.png';
import tablesTitles from '../../uploads/PdfData/tablePdf.json';
import elementsExploitationData from '../../uploads/PdfData/elementsExploitationData.json';
import forcesFaiblessesData from '../../uploads/PdfData/forces_faiblesses_data.json';
import graphsData from '../../uploads/PdfData/graphs_data.json';
import { useParams } from 'react-router-dom';
import { fetchEstimationApi, fetchEstimationWithSecondaryYearsApi } from '../../api/EstimationApi';
import AppContext from '../../context/AppContext';
import DescriptiveText from '../../components/pdfComponents/descriptiveText/DescriptiveText';
import CoverPagePdf from '../../components/pdfComponents/coverPagePdf/CoverPagePdf';
import { fetchDefaultVisitingCardApi } from '../../api/VisitingCardApi';
import OrganizationalChart from '../../components/pdfComponents/chartPdf/OrganizationalChart';
import SharedAquiredChart from '../../components/pdfComponents/chartPdf/SharedAquiredChart';
import ListeMaterielPdf from '../../components/pdfComponents/listeMaterielPdf/ListeMaterielPdf';

function Pdf({
  textColor = '#252B41',
  textSecondaryColor = '#ffffff',
  mainColor = '#891147',
  secondaryColor = '#6C79A4',
  font = 'Roboto',
  borderRadius = '5px',
  tableColor = '51, 51, 51', // ne marche qu'avec un format rgb
  tableSecondaryColor = '#ffffff',
  defaultMargin = '30px',
  data, // data qui viendra du parcours
}) {
  const [mainEstimationId, setMainEstimationId] = useState(); // id de l'estimation principale
  const [estimationYears, setEstimationYears] = useState([]); // années d'estimation
  const [summaryChapters, setSummaryChapters] = useState([]); // sommaire

  const [rapportData, setRapportData] = useState({});
  const [visitingCard, setVisitingCard] = useState(null); // carte de visite
  const [imagesPaths, setImagesPaths] = useState([]); // image de l'entreprise

  const { estimationIdParam } = useParams(); // id de l'estimation dans l'url
  const { createNotification, getUserId } = useContext(AppContext); // fonction de notification

  const [chartActivityMargin, setchartActivityMargin] = useState('');
  const [chartPerformance, setchartPerformance] = useState('');
  const [doughnutImageUrl, setDoughnutImageUrl] = useState('');

  const pdfContainerRef = useRef(null);

  useEffect(() => {
    fetchAllData();
  }, [estimationIdParam]);

  async function fetchAllData() {
    await fetchEstimation();
    await fetchVisitingCard();

    setSummaryChapters(generateSummaryChapters(pdfContainerRef, 0, [], 0)); // generate summary
  }

  async function fetchVisitingCard() {
    try {
      const visitingCard = (await fetchDefaultVisitingCardApi(getUserId())).data[0];
      setVisitingCard(visitingCard);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de la carte de visite</>,
        'var(--red)',
        'var(--grey)'
      );
    }
  }

  async function fetchEstimation() {
    try {
      const response = (await fetchEstimationWithSecondaryYearsApi(estimationIdParam)).data; // Récupère les données de l'estimation

      const estimationYears = []; // Tableau des années d'estimation
      const estimationData = (await fetchEstimationApi(response.base_estimation[0].id, true)).data;

      estimationYears.push(estimationData.estimation); // Récupère l'année principale
      for (const secondaryEstimation of response.related_estimations) {
        // Récupère les années secondaires
        estimationYears.push((await fetchEstimationApi(secondaryEstimation.id)).data.estimation);
      }

      estimationYears.forEach(year => {
        // Formatage des données
        year.compte_resultat.vente_marchandise +=
          year.compte_resultat.vente_marchandise_hebergement + year.compte_resultat.vente_marchandise_restauration;
        year.compte_resultat.production_exercice += year.compte_resultat.total_commissions_nettes;

        delete year.compte_resultat.vente_marchandise_hebergement;
        delete year.compte_resultat.vente_marchandise_restauration;
        delete year.compte_resultat.total_commissions_nettes;

        year.ratios.franchise.description = getFranchiseDescription(year);
        year.ratios.e_reputation = getEReputationDescription(year);
        year.ratios.diplome = getBesoinDiplomeDescription(year);
        year.etat_locatif = getEtatLocatifContent(year);

        delete year.etat_locatif.infos_complementaires;
      });

      getImagesPaths(getMainYearEstimation(estimationYears, response.base_estimation[0].id)); // Récupère les images de l'entreprise

      setMainEstimationId(response.base_estimation[0].id); // Définit l'année principale
      setEstimationYears(sortYears(estimationYears)); // Trie les années
      setRapportData(estimationData.rapport_data);
    } catch (error) {
      console.log(error);
      createNotification(<>Une erreur est survenue lors de la récupération des données</>, 'var(--red)', 'var(--grey)');
    }
  }

  function getImagesPaths(estimationYear) {
    console.log(estimationYear);
    const result = [];

    for (let image of Object.values(estimationYear?.images)) {
      if (image) {
        result.push(image);
      }
    }
    setImagesPaths(result);
  }

  function getImagesGroups(images) {
    let groups = [];
    const filteredImages = images.filter(img => img != null);
    for (let i = 0; i < filteredImages.length; i += 2) {
      groups.push(filteredImages.slice(i, i + 2));
    }
    return groups;
  }

  function getBusinessInfos(estimationYear) {
    const result = {
      name: estimationYear?.infos?.raison_sociale,
      type: estimationYear?.infos?.activite?.description,
      address: estimationYear?.infos?.adresse,
      siret: estimationYear?.infos?.siret,
      apeCode: 'N/A',
      legalRepresentative: 'Nom du représentant légal',
      creationDate: estimationYear?.date_creation_estimation.split(' ')[0],
      capital: formatEuroAmount(estimationYear?.bilan_actif?.capital_souscrit),
    };
    return result;
  }

  function getEtatLocatifContent(estimationYear) {
    return { ...estimationYear.etat_locatif };
  }

  function getBesoinDiplomeDescription(estimationYear) {
    return estimationYear.diplome_certification.description === 'Oui'
      ? "L'activité ne nécessite pas un diplôme, une certification, une carte professionnelle ou une licence (hormis Lic III et IV et agréments buraliste)"
      : "L'activité ne nécessite pas un diplôme, une certification, une carte professionnelle ou une licence (hormis Lic III et IV et agréments buraliste)";
  }

  function getFranchiseDescription(estimationYear) {
    return estimationYear.ratios.franchise.montant ? 'Oui' : 'Non';
  }

  function getEReputationDescription(estimationYear) {
    let result = [];
    if (estimationYear.e_reputation.note_google) result.push('Avis Google ' + estimationYear.e_reputation.note_google);
    if (estimationYear.e_reputation.note_tripadvisor)
      result.push('Avis Trip Advisor ' + estimationYear.e_reputation.note_tripadvisor);
    if (estimationYear.e_reputation.note_the_fork)
      result.push('Avis The Fork ' + estimationYear.e_reputation.note_the_fork);
    if (estimationYear.e_reputation.note_booking)
      result.push('Avis Booking ' + estimationYear.e_reputation.note_booking);
    if (estimationYear.e_reputation.note_autres) result.push('Autres ' + estimationYear.e_reputation.note_autres);

    return result.join(', ');
  }

  function sortYears(years) {
    return years.sort((a, b) => {
      const [dayA, monthA, yearA] = a?.infos?.date_bilan.split('/');
      const [dayB, monthB, yearB] = b?.infos?.date_bilan.split('/');
      return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
    });
  }

  function getMainYearEstimation(years, mainEstimationId) {
    // Récupère l'année principale de l'estimation
    return years.find(year => year.id === mainEstimationId);
  }

  function formatDataForTable(tableModel, years) {
    const result = {
      thead: [],
      tbody: [],
    };

    for (const intitule of Object.values(tableModel.intitules)) {
      const row = {
        type: intitule.type,
        cells: [intitule.name],
      };

      switch (intitule.key) {
        case 'head':
          years.forEach(year => row.cells.push(year.infos.date_bilan));
          result.thead.push(...row.cells);
          break;
        case 'autres':
          result.tbody.push(...formatDataFromMultipleInputs(tableModel.key, years, intitule.type));
          break;
        default:
          years.forEach(year => {
            row.cells.push(formatEuroAmount(year[tableModel.key][intitule.key]));
          });
          result.tbody.push(row);
          break;
      }
    }
    return result;
  }

  function formatDataFromMultipleInputs(key, years, type) {
    const result = [];

    if (!years[0] || !years[0][key]?.autres) return [];

    years[0][key]?.autres.forEach(intitule => {
      const row = {
        type: type,
        cells: [intitule.input],
      };

      years.forEach(year => {
        row.cells.push(formatEuroAmount(year[key]?.autres.filter(item => item.input === intitule.input)[0].value));
      });
      result.push(row);
    });

    return result;
  }

  function formatDataForTableFromMultipleObjects(tableModel, years) {
    const result = {
      thead: [],
      tbody: [],
    };

    for (const [key, intitule] of Object.entries(tableModel.intitules)) {
      const row = {
        type: intitule.type,
        cells: [intitule.name],
      };

      if (intitule.key === 'head') {
        years.forEach(year => row.cells.push(year.infos.date_bilan));
        result.thead.push(...row.cells);
      } else {
        years.forEach(year => {
          row.cells.push(
            formatEuroAmount(year[intitule.key1][intitule.key2], intitule.name.toUpperCase().includes('TAUX'))
          );
        });
        result.tbody.push(row);
      }
    }
    return result;
  }

  function formatDataForDescriptiveText(paragraphModel, mainYear) {
    const result = [];
    paragraphModel.forEach(group => {
      const row = {
        title: group.title,
        content: [],
      };

      group.intitules.forEach(intitule => {
        if (!mainYear) return;

        let boldText = '';
        const value = mainYear[group.key][intitule.key];
        typeof value === 'object' ? (boldText = value?.description) : (boldText = value);

        if (boldText)
          row.content.push({
            normalText: intitule.name,
            boldText: boldText + intitule.suffix,
          });
      });
      if (row.content.length) result.push(row);
    });
    return result;
  }

  function formatDataForRhTable(mainYear) {
    const result = {
      thead: [
        'Personnel',
        'Poste',
        <>
          Date
          <br />
          d'entrée
        </>,
        <>
          Type de
          <br />
          contrat
        </>,
        <>
          Rémunération
          <br />
          annuelle
        </>,
      ],
      tbody: [],
    };
    mainYear?.ratios?.detail_rh?.forEach(rh => {
      result.tbody.push({
        type: rh.is_key ? 2 : 1,
        cells: [rh.nom, rh.poste, rh.annee_entree, rh.type_contrat, formatEuroAmount(rh.salaire_brut_annuel)],
      });
    });

    return result;
  }

  function getActivityMarginChartData(estimationYear) {
    const result = {
      labels: [],
      datasets: [],
    };

    const labels = [];

    estimationYear.forEach(year => {
      labels.push(year.infos.date_bilan);
    });

    result.labels = labels;

    const datasets = [
      {
        label: 'Chiffre d’affaires',
        data: [],
        backgroundColor: 'rgba(37, 43, 65, 1)',
        borderColor: 'rgba(37, 43, 65, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'Marge globale',
        data: [],
        backgroundColor: 'rgba(109, 121, 164, 1)',
        borderColor: 'rgba(109, 121, 164, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'Résultat de l’exercice',
        data: [],
        backgroundColor: 'rgba(137, 17, 71, 1)',
        borderColor: 'rgba(137, 17, 71, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
    ];

    estimationYear.forEach(year => {
      datasets[0].data.push(year.compte_resultat.ca_global);
      datasets[1].data.push(year.compte_resultat.marge_brute_globale);
      datasets[2].data.push(year.compte_resultat.resultat_exercice);
    });

    result.datasets = datasets;

    return result;
  }

  function getPerformanceChartData(estimationYears) {
    const result = {
      labels: [],
      datasets: [],
    };

    const labels = [];

    estimationYears.forEach(year => {
      labels.push(year.infos.date_bilan);
    });

    result.labels = labels;

    const datasets = [
      {
        label: "Produits d'exploitation",
        data: [],
        backgroundColor: 'rgba(37, 43, 65, 1)',
        borderColor: 'rgba(37, 43, 65, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'Marge brute',
        data: [],
        backgroundColor: 'rgba(109, 121, 164, 1)',
        borderColor: 'rgba(109, 121, 164, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'Valeur ajoutée',
        data: [],
        backgroundColor: 'rgba(252, 227, 238, 1)',
        borderColor: 'rgba(252, 227, 238, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'EBE retraité',
        data: [],
        backgroundColor: 'rgba(202, 148, 172, 1)',
        borderColor: 'rgba(202, 148, 172, 1)',
        borderRadius: 5,
        borderSkipped: false,
      },
      {
        label: 'Résultat net',
        data: [],
        backgroundColor: 'rgba(137, 17, 71, 1)',
        borderColor: 'rgba(137, 17, 71, 1)',
        borderRadius: 5,
        borderSkipped: false,
      }
    ];

    estimationYears.forEach(year => {
      datasets[0].data.push(year.compte_resultat.ca_global);
      datasets[1].data.push(year.detail_marge.marge_brute_globale);
      datasets[2].data.push(year.compte_resultat.valeur_ajoutee);
      datasets[3].data.push(year.rentabilite_retraitee.ebe_retraite);
      datasets[4].data.push(year.compte_resultat.resultat_exercice);
    });

    result.datasets = datasets;

    return result;
  }

  function formatEuroAmount(amount, isPercentage = false) {
    if (amount === null || amount === undefined) return '-';
    let result = String(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    result += isPercentage ? ' %' : ' €';

    return result;
  }

  function getFinancialDoughnutData(estimationYear) {
    const turnover = estimationYear?.compte_resultat?.ca_global || 0;
    const payroll = formatDataForGraphs(graphsData[0], rapportData)?.masseSalariale || 0;
    const rentAndCharges = formatDataForGraphs(graphsData[0], rapportData)?.loyerCharges || 0;
    const performanceYear = 'Année N-1 : ' + (estimationYear?.infos?.date_bilan || '').split('/')[2];

    return {
      turnover,
      payroll,
      rentAndCharges,
      performanceYear,
    };
  }

  function getFinancialCirclePercentageData(estimationYear) {
    const percentage = estimationYear?.ratios?.potentiel_ca_3_ans || 0;
    const euroAmount = 'En cours de calcul';

    return {
      percentage,
      euroAmount,
    };
  }

  function formatDataForForcesFaiblesses(forcesFaiblessesModel, rapportData) {
    if (!rapportData?.forces_faiblesses) return;

    const result = {
      title: forcesFaiblessesModel?.title,
      content: [],
    };

    forcesFaiblessesModel.intitules.forEach(intitule => {
      const row = {
        title: intitule?.name,
        points: [],
      };

      if (rapportData?.forces_faiblesses[intitule.key]?.force) {
        row.points.push({
          isStrongPoint: true,
          content: rapportData?.forces_faiblesses[intitule.key]?.force,
        });
      }

      if (rapportData?.forces_faiblesses[intitule.key]?.faiblesse) {
        row.points.push({
          isStrongPoint: false,
          content: rapportData?.forces_faiblesses[intitule.key]?.faiblesse,
        });
      }

      if (row.points.length) result.content.push(row);
    });

    return result;
  }

  function formatDataForGraphs(graphModel, rapportData) {
    if (!rapportData.graphs) return;

    const result = {};

    result.potentielCA = rapportData?.graphs?.potentiel_ca;

    result.masseSalariale = rapportData?.graphs?.taux_effort_masse_salariale?.masse_salariale;
    result.loyerCharges = rapportData?.graphs?.taux_effort_masse_salariale?.loyer_charges;

    return result;
  }

  function formatDataForEstimationValorisationTable(mainYear, valueFDC = 3481174) {
    // TODO fetch estimation result
    // const valueTDS =
    //   valueFDC +
    //   mainYear?.materiel_agencements?.valeur_materiel_exploitation +
    //   mainYear?.bilan_actif?.immobilisations_financieres +
    //   mainYear?.bilan_actif?.total_actif_circulant -
    //   mainYear?.bilan_passif?.total_dettes;
    const valueTDS = 2656392;

    return {
      thead: ['Valeurs prises en compte', mainYear?.infos?.date_bilan.split('/')[2]],
      tbody: [
        {
          type: 1,
          cells: ['Fonds de commerce', formatEuroAmount(valueFDC)], // TODO adapter estimationValues
        },
        {
          type: 1,
          cells: [
            "+ Matériel d'exploitation",
            formatEuroAmount(mainYear?.materiel_agencements?.valeur_materiel_exploitation),
          ],
        },
        {
          type: 1,
          cells: [
            '+ Immobilisations financières',
            formatEuroAmount(mainYear?.bilan_actif?.immobilisations_financieres),
          ],
        },
        {
          type: 1,
          cells: ['+ Actif circulant', formatEuroAmount(mainYear?.bilan_actif?.total_actif_circulant)],
        },
        {
          type: 1,
          cells: ['- Dettes', formatEuroAmount(mainYear?.bilan_passif?.total_dettes)],
        },
        {
          type: 2,
          cells: ['= Valeur des titres de la société', formatEuroAmount(valueTDS)],
        },
      ],
    };
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const EstimationData = {
    hasTopArrows: true,
    values: [
      {
        estimation: '2390753',
        description: <>ESTIMATION BASSE</>,
      },
      {
        estimation: '2656392',
        description: <></>,
      },
      {
        estimation: '2922031',
        description: <>ESTIMATION HAUTE</>,
      },
    ],
  };

  const EstimationDetailsData = [
    {
      title: 'Sur la base du chiffre d’affaires HT moyen',
      subtitle: 'La valeur des titres de la société se situe dans la fourchette suivante :',
      lowAmount: '2 378 694',
      midAmount: '2 748 326',
      highAmount: '3 117 957',
    },
    {
      title: 'Sur la base de la rentabilité (EBE retraité) moyenne',
      subtitle: 'La valeur des titres de la société se situe dans la fourchette suivante :',
      lowAmount: '2 386 132',
      midAmount: '2 480 571',
      highAmount: '2 575 010',
    },
    {
      title: 'Sur la base du taux de profitabilité (rapport entre l’EBE et le CA HT)',
      subtitle: 'La valeur des titres de la société se situe dans la fourchette suivante :',
      lowAmount: '2 466 250',
      midAmount: '2 740 278',
      highAmount: '3 014 306',
    },
  ];

  const ValeurFdcData = {
    hasTopArrows: true,
    values: [
      {
        estimation: '3133057',
        description: <>ESTIMATION BASSE</>,
      },
      {
        estimation: '3481174',
        description: <>ESTIMATION MOYENNE</>,
      },
      {
        estimation: '3829291',
        description: <>ESTIMATION HAUTE</>,
      },
    ],
  };

  const calculationDetails = [
    {
      title: 'Sur la base du chiffre d’affaires HT moyen',
      sup: '1',
      subtitle: 'la valeur du fonds de commerce se situe dans la fourchette suivante :',
      lowAmount: '3 203 476',
      midAmount: '3 573 108',
      highAmount: '3 942 739',
      proportion: 'Soit une proportion moyenne du CA de : 294,51%',
    },
    {
      title: 'Sur la base de la rentabilité (EBE retraité) moyenne',
      sup: '2',
      subtitle: 'la valeur du fonds de commerce se situe dans la fourchette suivante :',
      lowAmount: '3 210 914',
      midAmount: '3 305 353',
      highAmount: '3 399 792',
      proportion: "Soit un coefficient moyen d'EBE retraité de : 7,11",
    },
    {
      title: 'Sur la base du taux de profitabilité (rapport entre l’EBE et le CA HT)',
      sup: '3',
      subtitle: 'la valeur du fonds de commerce se situe dans la fourchette suivante :',
      lowAmount: '3 208 554',
      midAmount: '3 565 060',
      highAmount: '3 921 566',
    },
  ];

  const MethodeValorisationPdfData = {
    thead: ['Valeurs prises en compte', '2023'],
    tbody: [
      {
        type: 1,
        cells: ['Fonds de commerce', '1 945 484 €'],
      },
      {
        type: 1,
        cells: ["+ Matériel d'exploitation", '265 109 €'],
      },
      {
        type: 1,
        cells: ['+ Immobilisations financières', '1 680 375 €'],
      },
      {
        type: 1,
        cells: ['+ Actif circulant', '1 680 375 €'],
      },
      {
        type: 1,
        cells: ['- Dettes', '1 680 375 €'],
      },
      {
        type: 2,
        cells: ['= Valeur des titres de la société', '24 097 €'],
      },
    ],
  };

  const shareholders = [
    {
      name: 'Philippe Durand',
      role: 'Président',
      percentage: '50%',
    },
    {
      name: 'Sophie Durand',
      role: 'Directeur général',
      percentage: '50%',
    },
  ];

  const companyName = 'SARL BELLANOTE';
  const subsidiaries = [
    {
      name: 'SARL A',
      siren: '878 066 455',
      percentage: '50%',
    },
    {
      name: 'SARL B',
      siren: '878 066 456',
      percentage: '50%',
    },
  ];

  const imageGroups = getImagesGroups(imagesPaths);

  return (
    <section className='flex f-center'>
      <div
        className='pdf-container'
        ref={pdfContainerRef}
        style={{
          '--text-color': textColor,
          '--text-secondary-color': textSecondaryColor,
          '--table-color': tableColor,
          '--table-secondary-color': tableSecondaryColor,
          '--main-color': mainColor,
          '--secondary-color': secondaryColor,
          '--font': font,
          '--border-radius': borderRadius,
          '--default-margin': defaultMargin,
        }}>
        <PagePdf>
          <CoverPagePdf
            logoUrl={logoUrl}
            reportTitle="Rapport d'estimation"
            businessInfo={getBusinessInfos(getMainYearEstimation(estimationYears, mainEstimationId))}
          />
        </PagePdf>
        <PagePdf pageActuelle={2} totalPages={29 + imageGroups.length}>
          <BannerPdf title='Sommaire' shouldAppearOnSummary={0} />
          <SummaryPdf summaryChapters={summaryChapters} />
        </PagePdf>
        <PagePdf pageActuelle={3} totalPages={29 + imageGroups.length}>
          <BannerPdf title='Préambule' />
          <p className='mt-lg'>
            Ce rapport a pour objectif d'évaluer l'entreprise sur la base{' '}
            <span style={{ fontWeight: '700' }}>
              d'éléments financiers, ainsi que toutes les facettes de l'exploitation,
            </span>{' '}
            incluant ses forces et faiblesses.
          </p>
          <p className='mt-lg'>
            Cette estimation a été réalisée avec une <span style={{ fontWeight: '700' }}>vision opérationnelle</span>,
            en intégrant chiffre d'affaires, rentabilité, rayonnement, état locatif, dynamique commerciale, aspects
            logistiques, ratios clefs, valeur ajoutée de l'exploitant et éléments d'actif et passif du bilan.
          </p>
          <p className='mt-lg'>
            L'évaluation d'une entreprise est étroitement liée à sa{' '}
            <span style={{ fontWeight: '700' }}>potentialité de transmission.</span>
          </p>
          <p className='mt-lg'>
            L'expérience du terrain nous a appris que la valeur d'une affaire ne tient pas qu'à des ratios comptables ou
            aux seuls souhaits du cédant.
          </p>
          <p className='mt-lg'>
            Elle doit constituer un juste équilibre entre ceux-ci et de nombreux autres facteurs, et assurer la{' '}
            <span style={{ fontWeight: '700' }}>finançabilité du projet</span> pour de potentiels repreneurs.
          </p>
          <p className='mt-lg'>
            Une affaire peut être transmise sous forme de fonds de commerce ou de titres de société.
          </p>
          <p className='mt-lg'>Ce rapport présente l'évaluation de l'entreprise en 4 parties :</p>
          <ul className='dots-list'>
            <li>Les éléments financiers et leur retraitement</li>
            <li>Les éléments d'exploitation</li>
            <li>L'évaluation</li>
            <li>Les annexes</li>
          </ul>
          <p className='mt-lg'>
            La fourchette de valeurs indiquée dans ce rapport ne constitue qu'une{' '}
            <span style={{ fontWeight: '700' }}>base de départ dans un éventuel projet de transmission</span>, dont la
            valeur finale négociée entre cédant et repreneur peut être très différente.
          </p>
          <p className='mt-lg'>
            Le présent rapport et son contenu n'ont aucune valeur juridique. Sa nature est purement informative.
          </p>
        </PagePdf>
        {imageGroups.map(
          (group, index) =>
            group.length > 0 && (
              <PagePdf key={index} pageActuelle={index + 4} totalPages={29 + imageGroups.length}>
                {index === 0 && <BannerPdf title={`Image${group.length > 1 ? 's' : ''} de l'affaire`} />}
                {group.map((imagePath, key) => (
                  <div key={key} className='pdf-business-image-container'>
                    <img src={imagePath} className='pdf-business-img' alt={`Photo de l'affaire ${key + 1}`} />
                  </div>
                ))}
              </PagePdf>
            )
        )}
        <PagePdf pageActuelle={imageGroups.length + 4} totalPages={29 + imageGroups.length}>
          <BannerPdf title='Organigramme' />
          <H3Pdf shouldAppearOnSummary={0} title='Actionnaires et bénéficiaires effectifs' />
          <OrganizationalChart shareholders={shareholders} />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 5} totalPages={29 + imageGroups.length}>
          <H3Pdf shouldAppearOnSummary={0} title="Titres détenus par l'entreprise" />
          <SharedAquiredChart
            companyName={getMainYearEstimation(estimationYears, mainEstimationId)?.infos?.raison_sociale}
            subsidiaries={subsidiaries}
          />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 6} totalPages={29 + imageGroups.length}>
          <BannerPdf title='Éléments financiers' />
          <H3Pdf title='Bilan Actif' />
          <TablePdf data={formatDataForTable(tablesTitles.bilan_actif, estimationYears)} />
          <p className='mt-lg'>
            {getMainYearEstimation(estimationYears, mainEstimationId)?.bilan_actif?.note_bilan_actif}
          </p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 7} totalPages={29 + imageGroups.length}>
          <H3Pdf title='Bilan Passif' />
          <TablePdf data={formatDataForTable(tablesTitles.bilan_passif, estimationYears)} />
          <H3Pdf title='Détail des dettes hors-exploitation' />
          <TablePdf data={formatDataForTable(tablesTitles.dettes, estimationYears)} />
          <p className='mt-lg'>
            {
              getMainYearEstimation(estimationYears, mainEstimationId)?.dettes_hors_exploitation
                ?.note_dettes_hors_exploitation
            }
          </p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 8} totalPages={29 + imageGroups.length}>
          <H3Pdf title='Compte de résultat' />
          <TablePdf data={formatDataForTable(tablesTitles.compte_resultat, estimationYears)} />
          <p className='mt-sm'>
            {getMainYearEstimation(estimationYears, mainEstimationId)?.compte_resultat?.note_compte_resultat}
          </p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 9} totalPages={29 + imageGroups.length}>
          <H3Pdf title="Détail de l'activité et de la marge" />
          <TablePdf data={formatDataForTableFromMultipleObjects(tablesTitles.activite_marge, estimationYears)} />
          <BarChart data={getActivityMarginChartData(estimationYears)} onImageReady={setchartActivityMargin} />
          {chartActivityMargin && (
            <img src={chartActivityMargin} alt="Graphique en barre pour l'activité et la marge" />
          )}
          <p className='mt-lg'>
            {getMainYearEstimation(estimationYears, mainEstimationId)?.detail_marge?.note_detail_marge}
          </p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 10} totalPages={29 + imageGroups.length}>
          <H3Pdf title='Retraitements' />
          <TablePdf data={formatDataForTable(tablesTitles.retraitements_remunerations, estimationYears)} />
          <TablePdf data={formatDataForTable(tablesTitles.retraitements_autres_charges, estimationYears)} />
          <p className='mt-lg'>
            {
              getMainYearEstimation(estimationYears, mainEstimationId)?.retraitements_autres_charges
                ?.note_retraitements_autres_charges
            }
          </p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 11} totalPages={29 + imageGroups.length}>
          <H3Pdf title='Récapitulatif de la rentabilité retraitée' />
          <p className='mt-lg'>
            {
              getMainYearEstimation(estimationYears, mainEstimationId)?.rentabilite_retraitee
                ?.note_rentabilite_retraitee
            }
          </p>
          <TablePdf data={formatDataForTableFromMultipleObjects(tablesTitles.rentabilite_retraitee, estimationYears)} />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 12} totalPages={29 + imageGroups.length}>
          <H3Pdf title='Les indicateurs de performance' />
          {/*
            <p className='mt-lg'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam blandit blandit. Aliquam eu laoreet
              diam. Integer sit amet mauris ac augue venenatis tincidunt.
              <br />
              <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam blandit blandit. Aliquam eu laoreet
              diam. Integer sit amet mauris ac augue venenatis tincidunt. In gravida quam in tincidunt molestie.
              Pellentesque a sollicitudin odio.
            </p>
          */}
          <BarChart
            data={getPerformanceChartData(estimationYears)}
            onImageReady={setchartPerformance}
            verticalText={true}
          />
          {chartPerformance && <img src={chartPerformance} alt="Graphique en barre pour l'indication de performance" />}
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 13} totalPages={29 + imageGroups.length}>
          <BannerPdf title="Eléments d'exploitation" />
          {formatDataForDescriptiveText(
            elementsExploitationData[0],
            getMainYearEstimation(estimationYears, mainEstimationId)
          ).map(group => (
            <DescriptiveText key={group.title} data={group} />
          ))}
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 14} totalPages={29 + imageGroups.length}>
          {formatDataForDescriptiveText(
            elementsExploitationData[1],
            getMainYearEstimation(estimationYears, mainEstimationId)
          ).map(group => (
            <DescriptiveText key={group.title} data={group} />
          ))}
          <H3Pdf title='Ressources humaines' />
          <TablePdf data={formatDataForRhTable(getMainYearEstimation(estimationYears, mainEstimationId))} />
          <div className='rh-table-legend'>
            <div /> Homme-clef
          </div>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 15} totalPages={29 + imageGroups.length}>
          <BannerPdf title="L'estimation" />
          <p className='mt-lg'>
            Sur la base de l’analyse de l’ensemble des éléments d’exploitation, nous évaluons les titres de la société{' '}
            <span className='outfit-bold '>
              {getMainYearEstimation(estimationYears, mainEstimationId)?.infos?.enseigne.toUpperCase()}
            </span>{' '}
            à :
          </p>
          <EstimationsRangePdf data={EstimationData} />
          <H3Pdf shouldAppearOnSummary={0} title="Détail de l'estimation" />
          <p className='mt-lg'>
            Cette valeur est une moyenne des prises en compte de différents indicateurs, ayant abouti aux valeurs
            suivantes :
          </p>
          <EstimationDetailsPdf data={EstimationDetailsData} />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 16} totalPages={29 + imageGroups.length}>
          <H1Pdf fontSize='76px' title='Annexes' />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 17} totalPages={29 + imageGroups.length}>
          {visitingCard && <VisitingCard {...visitingCard} className='pdf-visiting-card' />}
          <H3Pdf shouldAppearOnSummary={0} title='Mentions légales' />
          <p className='mt-lg'>{visitingCard?.mentions}</p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 18} totalPages={29 + imageGroups.length}>
          <BannerPdf title='Méthode de valorisation' />
          <H3Pdf title='Valeur du fonds de commerce' />
          <p className='mt-sm'>
            La valeur des titres de la société a été déterminée sur la base d'évaluation du fonds de commerce, qui est
            la <br />
            suivante :
          </p>
          <EstimationsRangePdf data={ValeurFdcData} className='value-fond-commerce' />
          <H3Pdf title='Détail du calcul' />
          <CalculationDetailsPdf details={calculationDetails} />
          <p className='mt-sm'>
            A partir de la valeur du fonds de commerce, la valeur des titres de la société{' '}
            <span className='outfit-bold '>
              {getMainYearEstimation(estimationYears, mainEstimationId)?.infos?.enseigne.toUpperCase()}
            </span>{' '}
            a été calculée de la manière suivante :
          </p>
          <TablePdf
            data={formatDataForEstimationValorisationTable(getMainYearEstimation(estimationYears, mainEstimationId))}
          />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 19} totalPages={29 + imageGroups.length}>
          <BannerPdf title="Notes sur l'estimation" />
          <p className='mt-lg'>
            Les fourchettes de valeur sont calculées à partir de la totalité des facteurs financiers et non-financiers
            de l'exploitation.
            <br />
            Chacun de ces facteurs impacte l'évaluation globale.
          </p>
          <H3Pdf title='Les comptes courants d’associés' />
          <p className='mt-lg'>
            Cette estimation est valable avec comme date de référence le bilan dont vous avez renseigné les éléments
            d'actif et de passif. Ces éléments, lorsqu'ils évolueront, impacteront la valeur des titres de la société.
          </p>
          <p className='mt-lg'>
            Dans le cadre d'une cession et en fonction de la négociation, un repreneur potentiel, en plus du prix des
            titres de société indiqué plus haut, sera susceptible de devoir rembourser au cédant (sauf s'il les
            abandonne partiellement ou totalement) ses comptes courants d'associés d'un montant de 396 363 €.
          </p>
          <H3Pdf title='Les stocks' />
          <p className='mt-lg'>
            La valeur des stocks réels au jour de la cession est incluse dans la valeur finale des titres de société.
          </p>
          <H3Pdf title='La moyenne de chiffres d’affaires annuels HT' />
          <p className='mt-lg'>
            <span style={{ color: 'var(--main-color)' }}>(1)</span> La moyenne des chiffres d'affaires HT traduit le
            volume d'affaires que l'exploitation a été (N-1 et N-2) ou sera (N et N+1) capable de réaliser. Cet
            indicateur est soumis à un coefficient multiplicateur dépendant à la fois du type d'activité et de
            l'ensemble des facteurs (implantation, attributs, ratios-clés, etc.), qui jouent un rôle pondérateur (à la
            hausse ou à la baisse, selon).
          </p>
          <H3Pdf title='La moyenne des rentabilités retraitées' />
          <p className='mt-lg'>
            <span style={{ color: 'var(--main-color)' }}>(2)</span> La moyenne des EBE retraités traduit la moyenne des
            rentabilités réelles annuelles dégagées par l'exploitation, et donc sa finançabilité. L'on adopte ainsi
            l'approche d'un repreneur potentiel, qui devra à la fois financer la reprise de l’affaire et dégager une
            rémunération pour tirer un revenu de l'exploitation. Cet indicateur essentiel est soumis à un coefficient
            multiplicateur spécifique à chaque activité et ajusté par l'ensemble des facteurs pondérateurs.
          </p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 20} totalPages={29 + imageGroups.length}>
          <H3Pdf title='Le taux de profitabilité' />
          <p className='mt-lg'>
            <span style={{ color: 'var(--main-color)' }}>(3)</span> Le taux de profitabilité met en perspective la
            rentabilité moyenne de l'affaire (EBE retraité) par rapport à son chiffre d'affaires moyen et détermine
            l'attractivité financière de l'affaire. On valorise donc mieux une affaire dégageant une meilleure
            rentabilité qu'une autre affaire à chiffre d'affaires égal, ou dégageant une rentabilité égale pour un
            chiffre d'affaires inférieur.
          </p>
          <H3Pdf title='Une estimation n’est pas un prix de vente' />
          <p className='mt-lg'>
            Dans un projet de cession de l'affaire, il est fondamental de prendre en compte différentes approches
            complémentaires. La valeur finale (prix effectif de cession) est le point de rencontre entre cédant et
            repreneur (et ses conseils comptables juridiques et financiers, dont l'avis compte dans la validation du
            prix).
          </p>
          <p className='mt-lg'>
            Dans un projet autre qu'une transaction, l'exploitant, bien que conseillé et encadré par des normes fiscales
            et de bon sens, reste seul décisionnaire de la valeur de son bien et se situera plus librement dans les
            fourchettes de valeurs.
          </p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 21} totalPages={29 + imageGroups.length}>
          <BannerPdf title="Taux d'effort & masse salariale" />
          <DoughnutChart
            turnover={getFinancialDoughnutData(getMainYearEstimation(estimationYears, mainEstimationId)).turnover}
            payroll={getFinancialDoughnutData(getMainYearEstimation(estimationYears, mainEstimationId)).payroll}
            rentAndCharges={
              getFinancialDoughnutData(getMainYearEstimation(estimationYears, mainEstimationId)).rentAndCharges
            }
            performanceYear={
              getFinancialDoughnutData(getMainYearEstimation(estimationYears, mainEstimationId)).performanceYear
            }
            onImageReady={setDoughnutImageUrl}
          />
          {doughnutImageUrl && (
            <div className='doughnut-chart'>
              <img src={doughnutImageUrl} alt='Graphique en donut pour la performance' />
            </div>
          )}
          <H3Pdf title="Le taux d'effort" />
          <p className='mt-lg'>
            Il représente le poids de la charge locative globale (loyers + charges + taxe foncière + droits de voirie)
            par rapport au chiffre d'affaires que réalise l'exploitation.
          </p>
          <p className='mt-lg'>
            En fonction de la dépendance de l'activité à un local commercial, cet indicateur peut être un facteur
            décisionnel important pour le repreneur potentiel, car le loyer est une charge incompressible d'une part, et
            en hausse constante d'autre part.
          </p>
          <H3Pdf title='Le taux de masse salariale' />
          <p className='mt-lg'>
            Il représente le poids des salaires (salaires bruts + charges sociales) des employés par rapport au chiffre
            d'affaires que réalise l'exploitation.
          </p>
          <p className='mt-lg'>
            C'est un facteur décisionnel important pour le repreneur potentiel, qui devra prévoir une masse salariale
            cohérente pour réaliser un chiffre d'affaires au moins équivalent au vôtre, en tenant compte du poste qu'il
            occupera, de son expérience et de ses compétences.
          </p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 22} totalPages={29 + imageGroups.length}>
          <BannerPdf title='Potentiel de croissance estimé sur 3 ans' />
          <CirclePercentagePdf
            percentage={
              getFinancialCirclePercentageData(getMainYearEstimation(estimationYears, mainEstimationId)).percentage
            }
            euroAmount={formatDataForGraphs(graphsData[0], rapportData)?.potentielCA}
            percentageText="Pourcentage estimé d'évolution des produits d'exploitation par rapport à l'année N-1"
            euroAmountText="Montant estimé d'évolution des produits d'exploitation par rapport à l'année N-1"
          />
          <p className='mt-lg'>
            Le potentiel de croissance moyenne estimé est conséquent et de nature à résolument motiver un repreneur
            potentiel, dans la mesure où les pistes de croissance sont clairement identifiées et réalistes. Considérant
            la perte naturelle d'une partie de la clientèle lors de la transmission, le repreneur dispose toutefois
            d'une marge de progression solide.
          </p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 23} totalPages={29 + imageGroups.length}>
          <BannerPdf title="Points forts et points faibles de l'affaire" />
          <StrongWeakPointsGroupPdf data={formatDataForForcesFaiblesses(forcesFaiblessesData[0], rapportData)} />
          <StrongWeakPointsGroupPdf data={formatDataForForcesFaiblesses(forcesFaiblessesData[1], rapportData)} />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 24} totalPages={29 + imageGroups.length}>
          <StrongWeakPointsGroupPdf data={formatDataForForcesFaiblesses(forcesFaiblessesData[2], rapportData)} />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 25} totalPages={29 + imageGroups.length}>
          <StrongWeakPointsGroupPdf data={formatDataForForcesFaiblesses(forcesFaiblessesData[3], rapportData)} />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 26} totalPages={29 + imageGroups.length}>
          <StrongWeakPointsGroupPdf data={formatDataForForcesFaiblesses(forcesFaiblessesData[4], rapportData)} />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 27} totalPages={29 + imageGroups.length}>
          <BannerPdf title='Liste du matériel' />
          <ListeMaterielPdf
            data={getMainYearEstimation(estimationYears, mainEstimationId)?.materiel_agencements?.liste_materiel}
          />
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 28} totalPages={29 + imageGroups.length}>
          <BannerPdf title='Précautions quant à la présente estimation' />
          <p className='mt-lg'>
            Un certain nombre de facteurs peuvent impacter la concordance entre le résultat de <br />
            l'estimation et le futur prix effectif de cession de l'affaire :
          </p>
          <H3Pdf shouldAppearOnSummary={0} title='Eléments inhérents à l’affaire' />
          <p className='mt-lg'>Tels que :</p>
          <ul className='dots-list'>
            <li>L'attrait que représente celle-ci pour des acquéreurs potentiels</li>
            <li>L'impératif de cession</li>
            <li>
              Plus globalement d'éventuels atouts ou défauts fortement disruptifs de l'affaire ou du <br />
              bien par rapport à des normes classiques de marché
            </li>
          </ul>
          <H3Pdf shouldAppearOnSummary={0} title='Eléments externes à l’affaire' />
          <p className='mt-lg'>Tels que :</p>
          <ul className='dots-list'>
            <li>Les fluctuations des paramètres du marché</li>
            <li>Les prix de cession effectifs</li>
            <li>
              Plus globalement d'éventuels paramètres géographiques, conjoncturels, sociaux ou <br />
              métier, qui seraient fortement disruptifs par rapport à des normes classiques de marché
            </li>
          </ul>
          <HrPdf />
          <p className='mt-lg'>
            La fixation du prix que le vendeur pense être en mesure d'obtenir dans le cadre d'une cession relève de son
            entière décision. Pour cela il est conseillé au vendeur, outre se baser sur le résultat d'estimation fourni
            par ce rapport, de se rapprocher de ses conseils habituels, ainsi que de faire appel à sa propre perception
            du marché et de la valeur de son bien.
          </p>
          <p className='mt-lg' style={{ color: 'var(--main-color)' }}>
            Rappelez-vous toujours une règle d’or en affaire : au-delà de toutes les estimations possibles, le prix
            final est celui où se rejoignent acquéreur et cédant.
          </p>
          <p className='mt-lg'>
            Le prix effectif de vente des titres de société ne pourra en aucun cas nous être opposé.
          </p>
        </PagePdf>
        <PagePdf pageActuelle={imageGroups.length + 29} totalPages={29 + imageGroups.length}>
          <H3Pdf shouldAppearOnSummary={0} title='La valeur indiquée dans ce rapport diffère de vos attentes ?' />
          <span className='mt-lg' style={{ color: 'var(--main-color)', fontSize: '1.1rem' }}>
            Vous vous attendiez à une valeur inférieure ?
          </span>
          <p className='mt-lg'>
            Personne ne connaît mieux l'affaire que l'exploitant lui-même. Bien que nous ayons tenu compte d'un grand
            nombre de facteurs pour réaliser cette évaluation, il est probable que des facteurs additionnels que nous ne
            connaissons pas viennent affecter la valeur de l'affaire à la baisse.
          </p>
          <span className='mt-lg' style={{ color: 'var(--main-color)', fontSize: '1.1rem' }}>
            Vous vous attendiez à une valeur supérieure ?
          </span>
          <p className='mt-lg'>
            En tant qu'exploitant, maître à bord de l'affaire, vous y avez consacré temps et énergie considérables. De
            ce fait, il est normal d’y affecter une valeur difficile à calculer, que l'on pourrait nommer valeur du
            travail ou valeur sentimentale. Cette valeur ne peut cependant pas être prise en compte dans l'évaluation.
          </p>
          <p className='mt-lg'>
            Si d'autres affaires ont pu être cédées à un prix « hors marché » relevant de ratios significativement
            supérieurs, rappelez-vous que nous avons pour objectif de livrer une évaluation réaliste, permettant, dans
            un projet de cession de l'affaire, d'obtenir les meilleures probabilités de trouver un repreneur dans des
            conditions de faisabilité convergentes pour les deux parties.
          </p>
          <p className='mt-lg'>
            Cependant, il est également possible que des facteurs additionnels intrinsèques à l'affaire viennent
            affecter sa valeur à la hausse.
          </p>
        </PagePdf>
      </div>

      <div className='top-scroller-container'>
        <div className='top-scroller' onClick={scrollToTop} />
      </div>
    </section>
  );
}

export default Pdf;
