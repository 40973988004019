import React from 'react';
import { ReactComponent as Xs } from '../../../../../assets/icons/parcours/zone_influence_xs.svg';
import { ReactComponent as Sm } from '../../../../../assets/icons/parcours/zone_influence_sm.svg';
import { ReactComponent as Lg } from '../../../../../assets/icons/parcours/zone_influence_lg.svg';
import { ReactComponent as Xl } from '../../../../../assets/icons/parcours/zone_influence_xl.svg';

function ZoneInfluence({ width = '100px', size = 'sm', className, color = 'var(--grey)' }) {
  const style = { '--color': color, 'stroke': 'var(--color' };

  return size === 'xs' ? (
    <Xs width={width} className={className} style={style} />
  ) : size === 'sm' ? (
    <Sm width={width} className={className} style={style} />
  ) : size === 'lg' ? (
    <Lg width={width} className={className} style={style} />
  ) : size === 'xl' ? (
    <Xl width={width} className={className} style={style} />
  ) : (
    <></>
  );
}

export default ZoneInfluence;
