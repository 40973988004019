import './CalculationDetailsPdf.css';
import React from 'react';
import TwistedArrow from '../../atoms/icons/pdf/twistedArrow/TwistedArrow';
import HrPdf from '../hrPdf/HrPdf';

function CalculationDetailsPdf({ details }) {

  // data = [
  //   {
  //     title: '',
  //     sup: '',
  //     subtitle: '',
  //     lowAmount: '',
  //     midAmount: '',
  //     highAmount: '',
  //     proportion: ''
  //   },
  // ];

  return (
    <div className="calculation-details-container">
      {details.map((detail, index) => (
        <div key={index}>
          <p className="calculation-title">
            {detail.title} 
            <sup className='calculation-title-sup'>({detail.sup})</sup>
          </p>
          <p className="calculation-subtitle">{detail.subtitle}</p>
          <div className="calculation-values">
            <div className='calculation-values-left-right'>
              <span className="calculation-amount">{detail.lowAmount} €</span>
              <div className="calculation-label">
                <TwistedArrow width='15px' />
                BASSE
              </div>
            </div>
            <hr className='calculation-hr-leftside'/>
            <div>
              <span className="calculation-amount-mid">{detail.midAmount} €</span>
              <div className="calculation-label-mid">MOYENNE</div>
            </div>
            <hr className='calculation-hr-rightside' />
            <div className='calculation-values-left-right'>
              <span className="calculation-amount">{detail.highAmount} €</span>
              <div className="calculation-label">
                HAUTE
                <TwistedArrow width='15px' color="var(--green)" rotation="180deg" />
              </div>
            </div>
          </div>
          <p className="calculation-proportion">{detail.proportion}</p>
          {index < details.length - 1 && <HrPdf/>}
        </div>
      ))}
    </div>
  );
}

export default CalculationDetailsPdf;