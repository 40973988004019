import React from 'react';
import loupe from '../../../../../assets/icons/general/search.svg';

function Search({ width, className = '', onClick }) {
  return (
    <img
      src={loupe}
      style={{ '--width': width }}
      className={className + (onClick ? ' clickable-icon' : '')}
      onClick={onClick}
    />
  );
}

export default Search;
