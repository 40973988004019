import React from 'react';
import './PaginationPdf.css';

function PaginationPdf({ currentPage, totalPages }) {
  const showSeparator = currentPage >= 2;

  return (
    <div className='pagination-container'>
      {showSeparator && <hr className="pagination-hr-separator"/>}
      {currentPage !== undefined && totalPages !== undefined && `${currentPage}/${totalPages}`}
    </div>
  );
}

export default PaginationPdf;