import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import Input from '../../../molecules/formComponents/input/Input';
import FilledButton from '../../../molecules/buttons/filledButton/FilledButton';
import { emailValidation, phoneValidation } from '../../../../utils/formValidation/FormValidation';

function ProfilForm({ userData }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!userData) return;
    for (const [key, value] of Object.entries(userData)) setValue(key, value);
  }, [userData]);

  function onSubmit(values) {
    //TODO
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='row-1000'>
        <Input register={register} error={errors?.last_name && errors.last_name.message} name='last_name' label='Nom' />
        <Input
          register={register}
          error={errors?.first_name && errors.first_name.message}
          name='first_name'
          label='Prénom'
        />
      </div>
      <Input
        register={register}
        error={errors?.street && errors.street.message}
        name='street'
        label='Adresse de facturation'
      />
      <div className='row-1000'>
        <Input
          register={register}
          error={errors?.zip_code && errors.zip_code.message}
          name='zip_code'
          label='Code postal'
        />
        <Input register={register} error={errors?.city && errors.city.message} name='city' label='Ville' />
      </div>
      <div className='row-1000'>
        <Input
          register={register}
          error={errors?.phone && errors.phone.message}
          name='phone'
          validation={phoneValidation}
          label='Téléphone'
        />
        <Input
          register={register}
          error={errors?.email && errors.email.message}
          name='email'
          disabled
          validation={emailValidation}
          label='Adresse mail'
        />
      </div>
      <FilledButton padding='10px 40px' className='mr-sm ml-auto' type='submit'>
        Valider
      </FilledButton>
    </form>
  );
}

export default ProfilForm;
