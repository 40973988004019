import { useForm } from 'react-hook-form';
import VisitingCard from '../../../organisms/visitingCard/VisitingCard';
import './VisitingCardForm.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Input from '../../../molecules/formComponents/input/Input';
import Textarea from '../../../molecules/formComponents/textarea/Textarea';
import FileInput from '../../../molecules/formComponents/fileInput/FileInput';
import Immeuble from '../../../atoms/icons/general/immeuble/Immeuble';
import User from '../../../atoms/icons/general/user/User';
import { checkImage, getImageInfos, isStickyElementPinned } from '../../../../utils/Utils';
import { useWindowScroll } from '@uidotdev/usehooks';
import { deleteVisitingCardApi, updateVisitingCardApi } from '../../../../api/VisitingCardApi';
import FilledButton from '../../../molecules/buttons/filledButton/FilledButton';
import UnfilledButton from '../../../molecules/buttons/unfilledButton/UnfilledButton';
import AppContext from '../../../../context/AppContext';

function VisitingCardForm({ visitingCardData, navbarRef, id, refreshData }) {
  const { register, setValue, getValues } = useForm();

  const [visitingCard, setVisitingCard] = useState(visitingCardData);
  const [imgErrorMessage, setImgErrorMessage] = useState({
    avatar: '',
    logo: '',
  });
  const [windowScroll] = useWindowScroll();
  const cardContainerRef = useRef(null);
  const { setModalVisible, setModalContent, createNotification } = useContext(AppContext);

  useEffect(() => {
    setVisitingCard({
      ...visitingCard,
    });

    Object.keys(getValues()).forEach(key => {
      if (key !== 'images') {
        setValue(key, visitingCardData[getInputNameWithoutId(key)]);
      }
    });

    navbarRef.current.style.maxHeight = '100px';
    navbarRef.current.style.transition = 'max-height 0.3s';
  }, [visitingCardData]);

  useEffect(() => {
    hideNavbarIfCardSticky();
  }, [windowScroll]);

  async function onImageChange(e, input) {
    try {
      const file = e.target.files[0];
      if (!file) return;

      let errorMsg = checkImage(file);
      if (!errorMsg) {
        const imageInfos = await getImageInfos(e);
        const response = (
          await updateVisitingCardApi(visitingCard.id, {
            ...visitingCard,
            [input + '_path']: null,
            images: { ...visitingCard.images, [input]: imageInfos },
          })
        ).data;
        setVisitingCard(response);
      }
      setImgErrorMessage({ ...imgErrorMessage, [input]: errorMsg });
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de votre carte de visite</>,
        'var(--red)',
        'var(--grey)'
      );
    }
  }

  function deleteImage(input) {
    setVisitingCard({ ...visitingCard, [input]: '' });
    setModalVisible(false);
  }

  function showDeleteImageModal(input) {
    setModalContent({
      title: "Supprimer l'image",
      content: (
        <>
          <p>Voulez-vous supprimer votre {input === 'avatar_path' ? 'avatar' : 'logo'} ? </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                setModalContent({ title: '', content: <></> });
              }}>
              Annuler
            </UnfilledButton>
            <FilledButton padding='10px 25px' onClick={() => deleteImage(input)}>
              Valider
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  async function setVisitingCardFormInput(input, event) {
    try {
      const response = (await updateVisitingCardApi(visitingCard.id, { [input]: event.target.value })).data;
      setVisitingCard(response);

      if (input === 'card_name') refreshData(visitingCard.id);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la mise à jour de votre carte de visite</>,
        'var(--red)',
        'var(--grey)'
      );
    }
  }

  function getInputNameWithoutId(inputName) {
    return inputName.split('-')[0];
  }

  function hideNavbarIfCardSticky() {
    if (document.getElementById('visiting-cards-expendable-group').value == id) {
      if (isStickyElementPinned(cardContainerRef, 80)) {
        navbarRef.current.style.maxHeight = '0';
        navbarRef.current.style.overflow = 'hidden';
      } else {
        navbarRef.current.style.maxHeight = '100px';
        navbarRef.current.style.overflow = '';
      }
    }
  }

  async function deleteVisitingCard(visitingCardId) {
    try {
      setModalVisible(false);
      await deleteVisitingCardApi(visitingCardId);
      refreshData();
      document.getElementsByClassName('card')[0].scrollIntoView();
      createNotification(<>Votre carte de visite a été supprimée avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de votre carte de visite</>,
        'var(--red)',
        'var(--grey)'
      );
    }
  }

  function showDeleteVisitingCardModale(visitingCard) {
    setModalContent({
      title: 'Supprimer la carte de visite',
      content: (
        <>
          <p>Voulez-vous supprimer la cart de visite "{visitingCard.card_name}"&nbsp;?</p>
          <div className='modal-buttons'>
            <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
            <FilledButton onClick={() => deleteVisitingCard(visitingCard.id)}>Confirmer</FilledButton>
          </div>
        </>
      ),
    });

    setModalVisible(true);
  }

  return (
    <div>
      <form>
        <Input
          name={`card_name-${id}`}
          register={register}
          label='Nom de la carte de visite'
          onBlur={e => setVisitingCardFormInput('card_name', e)}
        />
        {visitingCard.card_type === 'imported_card' ? (
          <>
            <div className='imported-card-container'>
              <img className='imported-visiting-card' alt='carte visite importée' src={visitingCard.card_path} />
              <Textarea
                label='mentions légales'
                onBlur={e => setVisitingCardFormInput('mentions', e)}
                register={register}
                name='mentions'
              />
            </div>
            <FilledButton
              className='m-auto'
              bgColor='var(--red)'
              padding='10px 20px'
              onClick={showDeleteVisitingCardModale}>
              Supprimer la carte de visite
            </FilledButton>
          </>
        ) : (
          <>
            <div className='visiting-card-profile-container' ref={cardContainerRef}>
              <VisitingCard {...visitingCard} />
            </div>
            <div className='row-1000 gap-md'>
              <FileInput
                name={`avatar-${id}`}
                register={register}
                icon={<User width='25px' />}
                label='Avatar'
                onChange={e => onImageChange(e, 'avatar')}
                setValue={setValue}
                onClear={() => showDeleteImageModal('avatar_path')}
                error={imgErrorMessage.avatar}
                removeButton={visitingCard?.avatar_path}
              />
              <FileInput
                name={`logo_path-${id}`}
                icon={<Immeuble width='25px' color='var(--white)' />}
                register={register}
                label='Logo'
                onChange={e => onImageChange(e, 'logo')}
                setValue={setValue}
                onClear={() => showDeleteImageModal('logo_path')}
                error={imgErrorMessage.logo}
                removeButton={visitingCard?.logo_path}
              />
            </div>
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput('first_name', e)}
                name={`first_name-${id}`}
                register={register}
                label='Prénom'
              />
              <Input
                onBlur={e => setVisitingCardFormInput('last_name', e)}
                name={`last_name-${id}`}
                register={register}
                label='Nom'
              />
            </div>
            <Input
              onBlur={e => setVisitingCardFormInput('position', e)}
              name={`position-${id}`}
              register={register}
              label='poste'
            />
            <Input
              onBlur={e => setVisitingCardFormInput('slogan', e)}
              name={`slogan-${id}`}
              register={register}
              label='slogan'
            />
            <Input
              onBlur={e => setVisitingCardFormInput('company', e)}
              name={`company-${id}`}
              register={register}
              label='société'
            />
            <Input
              onBlur={e => setVisitingCardFormInput('primary_address', e)}
              name={`primary_address-${id}`}
              register={register}
              label="ligne d'adresse 1"
            />
            <Input
              onBlur={e => setVisitingCardFormInput('secondary_address', e)}
              name={`secondary_address-${id}`}
              register={register}
              label="ligne d'adresse 2"
            />
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput('zip_code', e)}
                name={`zip_code-${id}`}
                register={register}
                label='code postal'
              />
              <Input
                onBlur={e => setVisitingCardFormInput('city', e)}
                name={`city-${id}`}
                register={register}
                label='ville'
              />
            </div>
            <hr className='visiting-card-form-hr' />
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput('email', e)}
                name={`email-${id}`}
                register={register}
                label='e-mail'
              />
              <Input
                onBlur={e => setVisitingCardFormInput('website', e)}
                name={`website-${id}`}
                register={register}
                label='site web'
              />
            </div>
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput('mobile_phone', e)}
                name={`mobile_phone-${id}`}
                register={register}
                label='téléphone portable'
              />
              <Input
                onBlur={e => setVisitingCardFormInput('fixed_phone', e)}
                name={`fixed_phone-${id}`}
                register={register}
                label='téléphone fixe'
              />
            </div>
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput('rsac', e)}
                name={`rsac-${id}`}
                register={register}
                label='rsac'
              />
              <Input
                onBlur={e => setVisitingCardFormInput('siret', e)}
                name={`siret-${id}`}
                register={register}
                label='siret'
              />
            </div>
            <hr className='visiting-card-form-hr' />
            <Textarea
              label='mentions légales'
              register={register}
              name='mentions'
              onBlur={e => setVisitingCardFormInput('mentions', e)}
            />
            <FilledButton
              className='m-auto'
              bgColor='var(--red)'
              padding='10px 20px'
              onClick={() => showDeleteVisitingCardModale(visitingCard)}>
              Supprimer la carte de visite
            </FilledButton>
          </>
        )}
      </form>
    </div>
  );
}

export default VisitingCardForm;
