import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/general/download.svg';

function Download({ width = '35px', color = 'var(--white)', className = '', onClick }) {
  return (
    <Icon
      style={{
        '--color': color,
        'stroke': 'var(--color)',
      }}
      width={width}
      height={width}
      className={className + (onClick ? ' clickable-icon' : '')}
      onClick={onClick}
    />
  );
}

export default Download;
