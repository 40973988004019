import React, { useContext, useEffect } from 'react';
import Input from '../../../../molecules/formComponents/input/Input';
import UnfilledButton from '../../../../molecules/buttons/unfilledButton/UnfilledButton';
import FilledButton from '../../../../molecules/buttons/filledButton/FilledButton';
import AppContext from '../../../../../context/AppContext';
import { defaultValidation } from '../../../../../utils/formValidation/FormValidation';
import { useForm } from 'react-hook-form';
import { duplicateEstimationApi } from '../../../../../api/EstimationApi';

function DuplicateEstimationModal({ estimationId, defaultValue, fetchEstimationsFunction = async () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    setValue('enseigne', defaultValue);
  }, [defaultValue]);

  async function duplicateEstimation(values) {
    try {
      await duplicateEstimationApi(estimationId, values.enseigne);
      await fetchEstimationsFunction();
    } catch (error) {
      console.log(error);
      createNotification(
        <>Une erreur est survenue lors de la duplication de votre estimation. Veuillez réessayer plus tard</>,
        'var(--red)',
        'var(--grey)'
      );
    }
    setModalVisible(false);
  }
  return (
    <form onSubmit={handleSubmit(duplicateEstimation)}>
      <Input
        validation={defaultValidation}
        register={register}
        error={errors?.enseigne && errors?.enseigne.message}
        name='enseigne'
        label="Nom de l'enseigne"
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Dupliquer l'estimation
        </FilledButton>
      </div>
    </form>
  );
}

export default DuplicateEstimationModal;
