import React from 'react'
import './HrPdf.css'

function HrPdf({className = ''}) {
  return (
    <hr className={`hr-separator ${className}`}/>
  )
}

export default HrPdf;
