import React, { useContext, useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import Info from '../../../../atoms/icons/general/info/Info';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import Radio from '../../../../molecules/formComponents/radio/Radio';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import {
  numberValidation,
  decimalValidation,
  max2500Validation,
  rate5Validation,
  rate10Validation,
} from '../../../../../utils/formValidation/FormValidation';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import Checkbox from '../../../../molecules/formComponents/checkbox/Checkbox';
import Input from '../../../../molecules/formComponents/input/Input';
import Trash from '../../../../atoms/icons/general/trash/Trash';
import './RatioClefs.css';
import FilledButton from '../../../../molecules/buttons/filledButton/FilledButton';
import Add from '../../../../atoms/icons/general/add/Add';
import { useWindowSize } from '@uidotdev/usehooks';
import {
  formatDate,
  formatObjectForPosting,
  getFormattedDate,
  getSelectValue,
  removeSpaces,
} from '../../../../../utils/Utils';
import Card from '../../../../molecules/cards/card/Card';
import { deleteRhApi } from '../../../../../api/EstimationApi';
import RhMultiplesInputs, {
  fillMultipleRhInputs,
  removeSpacesFromRhMultiplesInputs,
} from '../../../../molecules/formComponents/rhMultiplesInputs/RhMultiplesInputs';

function RatioClefs({ id, index }) {
  const {
    register,
    watch,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const Form = { register, watch, setValue };
  const {
    typesCommerce,
    step,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
    fillSelectsFromEstimationValues,
  } = useContext(ParcoursContext);
  const detailRh = watch('detail_rh');
  const franchise = watch('franchise');
  const infosComplementaires = watch('infos_complementaires_12');
  const [rh, setRh] = useState([
    { index: 0, visible: true, error: { nom: '', poste: '', salaire: '', date_entree: '', type_contrat: '' } },
  ]);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'ratios',
      ['salaries', 'potentiel_ca_3_ans', 'pourcentage_liquide_bar', 'jours_ouverture'],
      setValue,
      estimationValues
    );
    fillInputsFromEstimationValues('hotellerie', [
      'couverts_jour',
      'hectolitres_biere_an',
      'kg_cafe_semaine',
      'prix_moyen_chambre_ht',
      'taux_occupation_moyen',
      'ticket_moyen_client_ht',
    ]);
    fillSelectsFromEstimationValues(
      'ratios',
      ['anciennete_exploitation_affaire', 'client_dependance'],
      setValue,
      estimationValues
    );
    if (estimationValues?.ratios?.franchise?.montant) {
      setValue('franchise', '2');
      setValue('montant', estimationValues?.ratios?.franchise.montant);
    } else if(step >= index) {
      setValue('franchise', '1');
    }
    if (estimationValues?.ratios?.detail_rh?.length) {
      setValue('detail_rh', true);
      fillMultipleRhInputs(estimationValues?.ratios?.detail_rh, setValue, setRh, 'rh');
    }

    if (estimationValues?.ratios?.infos_complementaires?.amplitude_horaire) {
      setValue('infos_complementaires_12', true);
      fillInputsFromEstimationValues(
        'infos_complementaires',
        ['amplitude_horaire', 'fermeture_hebdomadaire', 'conges_annuels', 'motif'],
        setValue,
        estimationValues?.ratios
      );
      setValue(
        'date_debut_exploitation',
        formatDate(estimationValues?.ratios?.infos_complementaires?.date_debut_exploitation)
      );
    } else setValue('infos_complementaires_12', false);

    fillInputsFromEstimationValues(
      'e_reputation',
      ['note_autre', 'note_google', 'note_the_fork', 'note_trip_advisor', 'note_booking'],
      setValue,
      estimationValues
    );

    setValue('diplome_certification', estimationValues?.diplome_certification?.id + '');

    fillInputsFromEstimationValues('coef_ponderation', ['coef_1', 'coef_2', 'coef_3'], setValue, estimationValues);
  }, [estimationValues]);

  async function formSubmit(values) {
    const formattedNumbers = formatObjectForPosting(values);
    const tmp = {
      ratios: {
        salaries: formattedNumbers.salaries,
        potentiel_ca_3_ans: formattedNumbers.potentiel_ca_3_ans,
        detail_rh: values.detail_rh ? removeSpacesFromRhMultiplesInputs(values.rh) : [],
        pourcentage_liquide_bar: formattedNumbers.pourcentage_liquides_bar,
        anciennete_exploitation_affaire: { id: values.anciennete_exploitation_affaire },
        jours_ouverture: formattedNumbers.jours_ouverture,
        client_dependance: { id: values.client_dependance },
        franchise: { montant: values.franchise == 2 ? formattedNumbers.montant : null },
        infos_complementaires: {
          amplitude_horaire: values.infos_complementaires_12 ? values.amplitude_horaire : null,
          fermeture_hebdomadaire: values.infos_complementaires_12 ? values.fermeture_hebdomadaire : null,
          conges_annuels: values.infos_complementaires_12 ? values.conges_annuels : null,
          motif: values.infos_complementaires_12 ? values.motif : null,
          date_debut_exploitation: values.infos_complementaires_12 ? getFormattedDate(values.date_debut_exploitation) : null,
        },
        hotellerie: {
          taux_occupation_moyen: formattedNumbers.taux_occupation_moyen,
          prix_moyen_chambre_ht: formattedNumbers.prix_moyen_chambre_ht,
          ticket_moyen_client_ht: formattedNumbers.ticket_moyen_client_ht,
          couverts_jour: formattedNumbers.couverts_jour,
          kg_cafe_semaine: formattedNumbers.kg_cafe_semaine,
          hectolitres_biere_an: formattedNumbers.hectolitres_biere_an,
        },
      },
      e_reputation: {
        note_google: formattedNumbers.note_google,
        note_trip_advisor: formattedNumbers.note_tripadvisor,
        note_the_fork: formattedNumbers.note_the_fork,
        note_booking: formattedNumbers.note_booking,
        note_autre: formattedNumbers.note_autre,
      },
      coef_ponderation: {
        coef_1: formattedNumbers.coef_1,
        coef_2: formattedNumbers.coef_2,
        coef_3: formattedNumbers.coef_3,
      },
      diplome_certification: { id: values.diplome_certification },
    };

    postValues(tmp);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>ratios clefs & informations complémentaires</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            label='nombre de salariés travaillant dans l’entreprise, hormis le dirigeant'
            error={errors?.salaries && errors.salaries.message}
            name='salaries'
            validation={numberValidation}
          />
          <NumberInput
            useForm={Form}
            className={typesCommerce.includes(3) ? '' : 'w-0'}
            visible={typesCommerce.includes(3)}
            label='pourcentage de liquides (ou limonade) par rapport aux solides'
            error={errors?.pourcentage_liquides_bar && errors.pourcentage_liquides_bar.message}
            name='pourcentage_liquides_bar'
            icon='percent'
            validation={decimalValidation}
          />
          <NumberInput
            useForm={Form}
            label='potentiel de développement du chiffre d’affaires à 3 ans'
            error={errors?.potentiel_ca_3_ans && errors.potentiel_ca_3_ans.message}
            name='potentiel_ca_3_ans'
            icon='percent'
            validation={decimalValidation}
          />
        </div>
        <Checkbox label='je souhaite détailler les ressources humaines' name='detail_rh' register={register} />
        <RhMultiplesInputs
          Form={Form}
          unregister={unregister}
          values={rh}
          setValues={setRh}
          name='rh'
          visible={detailRh}
        />
        <Radio
          label="ancienneté d’exploitation de l'affaire"
          register={register}
          error={errors?.anciennete_exploitation_affaire && errors.anciennete_exploitation_affaire.message}
          name='anciennete_exploitation_affaire'
          content={[
            {
              value: 1,
              label: '< 1 an',
            },
            {
              value: 2,
              label: '1 à 2 ans',
            },
            {
              value: 3,
              label: '2 à 3 ans',
            },
            {
              value: 4,
              label: '3 à 4 ans',
            },
            {
              value: 5,
              label: '4 à 7 ans',
            },
            {
              value: 6,
              label: '> 7 ans',
            },
          ]}
        />
        <div className='row-1000'>
          <NumberInput
            visible={typesCommerce.includes(8)}
            useForm={Form}
            icon='percent'
            label="Taux d'occupation moyen"
            error={errors?.taux_occupation_moyen && errors.taux_occupation_moyen.message}
            name='taux_occupation_moyen'
            validation={decimalValidation}
          />
          <NumberInput
            visible={typesCommerce.includes(8)}
            useForm={Form}
            icon='euro'
            label='Prix moyen chambre HT'
            error={errors?.prix_moyen_chambre_ht && errors.prix_moyen_chambre_ht.message}
            name='prix_moyen_chambre_ht'
            validation={decimalValidation}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            visible={typesCommerce.includes(9)}
            useForm={Form}
            icon='euro'
            label='Ticket moyen par client (ht)'
            error={errors?.ticket_moyen_client_ht && errors.ticket_moyen_client_ht.message}
            name='ticket_moyen_client_ht'
            validation={decimalValidation}
          />
          <NumberInput
            visible={typesCommerce.includes(9)}
            useForm={Form}
            label='Nombre moyen de couverts / jour'
            error={errors?.couverts_jour && errors.couverts_jour.message}
            name='couverts_jour'
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            visible={typesCommerce.includes(9)}
            useForm={Form}
            label='nombre de kg de café / semaine'
            error={errors?.kg_cafe_semaine && errors.kg_cafe_semaine.message}
            name='kg_cafe_semaine'
            validation={decimalValidation}
          />
          <NumberInput
            visible={typesCommerce.includes(9)}
            useForm={Form}
            label="nombre moyen d'hectolitres de bière / an"
            error={errors?.hectolitres_biere_an && errors.hectolitres_biere_an.message}
            name='hectolitres_biere_an'
            validation={decimalValidation}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            label='nombre de jours d’ouverture dans l’année'
            error={errors?.jours_ouverture && errors.jours_ouverture.message}
            name='jours_ouverture'
          />
          <Radio
            label='50% ou plus du ca sont ils réalisés par 1 à 3 clients ?'
            register={register}
            error={errors?.client_dependance && errors.client_dependance.message}
            name='client_dependance'
            content={[
              {
                value: 1,
                label: 'Non',
              },
              {
                value: 2,
                label: 'Oui',
              },
            ]}
          />
        </div>
        <div className='row-1000'>
          <Radio
            label="l'exploitant est-il franchisé / adhérent / concessionnaire / licencié d’une enseigne&nbsp;?"
            register={register}
            error={errors?.franchise && errors.franchise.message}
            name='franchise'
            content={[
              {
                value: 1,
                label: 'Non',
              },
              {
                value: 2,
                label: 'Oui',
              },
            ]}
          />
          <div className='w-100' />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            visible={franchise == 2}
            label="montant de la franchise (droit d'entrée)"
            error={errors?.montant && errors.montant.message}
            name='montant'
            validation={numberValidation}
            icon='euro'
          />
          <div className='w-100' />
        </div>
        <Checkbox
          label="je souhaite renseigner des informations complémentaires sur le fonctionnement de l'affaire"
          name='infos_complementaires_12'
          register={register}
        />
        <div className='row-1000'>
          <Input
            visible={infosComplementaires}
            label="Le vendeur exploite l'affaire depuis le"
            type='date'
            name='date_debut_exploitation'
            error={errors?.date_debut_exploitation && errors.date_debut_exploitation.message}
            register={register}
          />
          <Input
            visible={infosComplementaires}
            label='Amplitude horaire'
            name='amplitude_horaire'
            error={errors?.amplitude_horaire && errors.amplitude_horaire.message}
            register={register}
          />
        </div>
        <div className='row-1000'>
          <Input
            visible={infosComplementaires}
            label='Fermeture hebdomadaire'
            name='fermeture_hebdomadaire'
            error={errors?.fermeture_hebdomadaire && errors.fermeture_hebdomadaire.message}
            register={register}
          />
          <Input
            visible={infosComplementaires}
            label='Congés annuels'
            name='conges_annuels'
            error={errors?.conges_annuels && errors.conges_annuels.message}
            register={register}
          />
        </div>
        <Textarea
          className='small-textarea'
          visible={infosComplementaires}
          register={register}
          name='motif'
          error={errors?.motif && errors.motif.message}
          label='motif de la vente'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2 className='flex gap-sm'>e-réputation</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            zeboByDefault={false}
            className={typesCommerce.includes(2) || typesCommerce.includes(4) ? '' : 'w-0'}
            visible={typesCommerce.includes(2) || typesCommerce.includes(4)}
            label='tripadvisor (note de 1 à 5)'
            error={errors?.note_tripadvisor && errors.note_tripadvisor.message}
            name='note_tripadvisor'
            validation={rate5Validation}
          />
          <NumberInput
            useForm={Form}
            zeboByDefault={false}
            label='avis google (note de 1 à 5)'
            error={errors?.note_google && errors.note_google.message}
            name='note_google'
            type='number'
            validation={rate5Validation}
          />
          <NumberInput
            useForm={Form}
            zeboByDefault={false}
            className={typesCommerce.includes(2) || typesCommerce.includes(4) ? '' : 'w-0'}
            visible={typesCommerce.includes(2) || typesCommerce.includes(4)}
            label='the fork (note de 1 à 10)'
            error={errors?.note_the_fork && errors.note_the_fork.message}
            name='note_the_fork'
            type='number'
            validation={rate10Validation}
          />
          <NumberInput
            useForm={Form}
            zeboByDefault={false}
            className={typesCommerce.includes(4) ? '' : 'w-0'}
            visible={typesCommerce.includes(4)}
            label='booking (note de 1 à 10)'
            error={errors?.note_booking && errors.note_booking.message}
            name='note_booking'
            type='number'
            validation={rate10Validation}
          />
          <NumberInput
            useForm={Form}
            zeboByDefault={false}
            label='autres (note de 1 à 5)'
            error={errors?.note_autre && errors.note_autre.message}
            name='note_autre'
            type='number'
            validation={rate5Validation}
          />
        </div>
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>diplômes & certifications</h2>
        <Radio
          label='l’activité nécessite-t-elle un diplôme, une certification, une carte professionnelle ou une licence ?
              (hormis lic iii et iv et agréments buraliste)'
          error={errors?.diplome_certification && errors.diplome_certification.message}
          register={register}
          content={[
            { value: 1, label: 'Non' },
            { value: 2, label: 'Oui' },
          ]}
          name='diplome_certification'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>coefficients de pondération</h2>
        <div className='form-subtitle'>A appliquer sur les valeurs obtenues à l'issue de l'estimation initiale</div>
        <NumberInput
          useForm={Form}
          label="fourchette de volume d'activité moyen"
          name='coef_1'
          error={errors?.coef_1 && errors.coef_1.message}
          icon='percent'
          validation={decimalValidation}
        />
        <NumberInput
          useForm={Form}
          label='fourchette de rentabilité moyenne'
          name='coef_2'
          error={errors?.coef_2 && errors.coef_2.message}
          icon='percent'
          validation={decimalValidation}
        />
        <NumberInput
          useForm={Form}
          label="fourchette de rentabilité proportionnelle au volume d'activité"
          name='coef_3'
          error={errors?.coef_3 && errors.coef_3.message}
          icon='percent'
          validation={decimalValidation}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default RatioClefs;
