import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../../../molecules/formComponents/input/Input.js';
import { max2500Validation, numberValidation } from '../../../../../utils/formValidation/FormValidation.js';
import './DefinitionAffaire.css';
import { ParcoursContext } from '../../../../../context/ParcoursContext.js';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea.js';
import { formatDate, getFormattedDate, getImageInfos } from '../../../../../utils/Utils.js';
import Info from '../../../../atoms/icons/general/info/Info.js';
import FileInputPreview from '../../../../molecules/formComponents/fileInputPreview/FileInputPreview.js';
import { deleteEstimationImageApi, updateEstimationImagesApi } from '../../../../../api/EstimationApi.js';

function DefinitionAffaire({ id, index }) {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const {
    showTooltip,
    moveToNextStep,
    step,
    activites,
    setStep,
    getStepToGo,
    setGoToStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const [activitesDropdownVisible, setActivitesDropdownVisible] = useState(false);
  const [displayedActivites, setDisplayedActivites] = useState(activites);
  const watchActivite = watch('activite');

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'infos',
      ['enseigne', 'ville', 'code_postal', 'rue', 'raison_sociale', 'siret'],
      setValue,
      estimationValues
    );
    if (estimationValues?.infos?.date_bilan) setValue('date_bilan', formatDate(estimationValues?.infos?.date_bilan));
    else setValue('date_bilan', formatDate('31/12/2023'));
    setValue('activite', estimationValues?.infos?.activite?.description);
    setValue('note_step_3', estimationValues?.notes?.note_step_3);
  }, [estimationValues]);

  useEffect(() => {
    if (!watchActivite) return setDisplayedActivites(activites);

    setDisplayedActivites(
      activites.filter(element => element.description.toLowerCase().includes(watchActivite.toLowerCase()))
    );
  }, [activites, watchActivite]);

  async function postImages(estimationId, values){
    try{
      const payload = {
          image_1: await getImageInfos(null, values.image_1),
          image_2: await getImageInfos(null, values.image_2),
          image_3: await getImageInfos(null, values.image_3),
          image_4: await getImageInfos(null, values.image_4)
      }
  
      updateEstimationImagesApi(estimationId, payload);
    } catch (e) {
      console.log(e);
    }
  }

  async function onImageClear(e, name){
    try{
      if(!estimationValues.id) return;
      await deleteEstimationImageApi(estimationValues.id, name);
    } catch(e){
      console.log(e);
    }
  }

  async function formSubmit(values) {
    try {
      let activiteId;
      if (activites.length) {
        activiteId = activites.find(element => element.description === values.activite)?.id;
        if (!activiteId) {
          setError('activite', { message: 'Veuillez choisir une activité dans la liste' }, { shouldFocus: true });
          document.getElementById('activite').focus();
          return;
        }
      }

      let payload = {
        infos: values,
      };

      payload.infos.activite = { id: activiteId };
      payload.infos.date_bilan = getFormattedDate(payload.infos.date_bilan);

      const estimationId = await postValues(payload);

      postImages(estimationId, values);

      if (getStepToGo() != 0) {
        setStep(getStepToGo());
        setGoToStep(0);
      } else moveToNextStep(index);
    } catch (e) {
      console.log(e);
    }
  }

  function setChosenActivite(activite) {
    setValue('activite', activite.description);

    setTimeout(() => {
      setActivitesDropdownVisible(false);
    }, 200);
  }

  function closeDropwdown() {
    setTimeout(() => {
      setActivitesDropdownVisible(false);
    }, 200);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <fieldset className='borderless-fieldset'>
        <h2>définissons l’affaire à évaluer</h2>
        <Input
          setValue={setValue}
          placeholder='Cliquez et déroulez, ou cherchez par mot-clé...'
          label='activité (vous ne pourrez pas la changer par la suite)'
          name='activite'
          register={register}
          disabled={estimationValues?.infos?.activite?.description ? true : false}
          error={errors?.activite && errors.activite.message}
          icon='search'
          onFocus={() => setActivitesDropdownVisible(true)}
          onBlur={closeDropwdown}
          preventAutoComplete
          validation={{}}
          bgColor='var(--main-color)'>
          <ul className={'activites-list' + (activitesDropdownVisible ? '' : ' h-none')}>
            {displayedActivites.map((activite, key) => {
              return (
                <li key={key} onClick={() => setChosenActivite(activite)}>
                  {activite.description}
                </li>
              );
            })}
            {displayedActivites.length === 0 && <li className='activites-no-result'>Aucun résultat</li>}
          </ul>
        </Input>
        <Input
          setValue={setValue}
          label='enseigne / nom de l’affaire à évaluer'
          register={register}
          error={errors?.enseigne && errors.enseigne.message}
          name='enseigne'
        />
        <Input
          setValue={setValue}
          label='n°/nom de rue'
          register={register}
          error={errors?.rue && errors.rue.message}
          name='rue'
        />
        <div className='row-1000 mb-sm'>
          <Input
            setValue={setValue}
            label='ville'
            register={register}
            error={errors?.ville && errors.ville.message}
            name='ville'
          />
          <Input
            setValue={setValue}
            label='code postal'
            register={register}
            error={errors?.code_postal && errors.code_postal.message}
            name='code_postal'
          />
        </div>
        <div className='row-1000 mb-sm'>
          <Input
            setValue={setValue}
            label='raison sociale'
            register={register}
            error={errors?.raison_sociale && errors.raison_sociale.message}
            name='raison_sociale'
          />
          <Input
            setValue={setValue}
            label='siret'
            validation={numberValidation}
            register={register}
            error={errors?.siret && errors.siret.message}
            name='siret'
          />
        </div>
        <Input
          setValue={setValue}
          name='date_bilan'
          register={register}
          type='date'
          label={
            <>
              date du dernier bilan
              <Info fill='var(--grey)' onClick={() => showTooltip('Tutoetape3FDCtitreschoixanneedernierbilan.webp')} />
            </>
          }
          bgColor='var(--main-color)'
        />
        <div className='row-1000 gap-md mt-md space-between'>
          <FileInputPreview
            setValue={setValue}
            name='image_1'
            register={register}
            label='image 1'
            value={estimationValues?.images?.image_1}
            onClear={onImageClear}
          />
          <FileInputPreview
            setValue={setValue}
            name='image_2'
            register={register}
            label='image 2'
            value={estimationValues?.images?.image_2}
            onClear={onImageClear}
          />
          <FileInputPreview
            setValue={setValue}
            name='image_3'
            register={register}
            label='image 3'
            value={estimationValues?.images?.image_3}
            onClear={onImageClear}
          />
          <FileInputPreview
            setValue={setValue}
            name='image_4'
            register={register}
            label='image 4'
            value={estimationValues?.images?.image_4}
            onClear={onImageClear}
          />
        </div>
      </fieldset>
    </form>
  );
}

export default DefinitionAffaire;