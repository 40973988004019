import TwistedArrow from '../../atoms/icons/pdf/twistedArrow/TwistedArrow';
import './EstimationsRangePdf.css';
import React from 'react';

// data = {
//   hasTopArrows: false,
//   values: [
//     {
//       estimation: '',
//       description: <></>,
//     },
//     {
//       estimation: '',
//       description: <></>,
//     },
//     {
//       estimation: '',
//       description: <></>,
//     },
//   ],
// };

function getEmbededValue(value) {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €';
}

function EstimationsRangePdf({ data, className = '' }) {
  return (
    <div className={`estimations-range-container-pdf ${className}`}>
      <div className='side-estimation-range-container'>
        <div className='estimation-range-subtext'>{data.hasTopArrows && <TwistedArrow width='30px' />}</div>
        <div className='estimation-range-value'>{getEmbededValue(data.values[0].estimation)}</div>
        <div className='estimation-range-subtext'>{data.values[0].description}</div>
      </div>
      <div className='center-estimation-range-container'>
        <div className='estimation-range-subtext'></div>
        <div className='estimation-range-value'>{getEmbededValue(data.values[1].estimation)}</div>
        <div className='estimation-range-subtext'>{data.values[1].description}</div>
      </div>
      <div className='side-estimation-range-container'>
        <div className='estimation-range-subtext'>
          {data.hasTopArrows && <TwistedArrow width='30px' color='var(--green)' rotation='180deg' />}
        </div>
        <div className='estimation-range-value'>{getEmbededValue(data.values[2].estimation)}</div>
        <div className='estimation-range-subtext'>{data.values[2].description}</div>
      </div>
    </div>
  );
}

export default EstimationsRangePdf;
