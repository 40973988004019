import './MonProfil.css';
import React, { useContext, useEffect, useState } from 'react';
import Card from '../../../../../components/molecules/cards/card/Card';
import FilledButton from '../../../../../components/molecules/buttons/filledButton/FilledButton';
import ExpendableGroup from '../../../../../components/organisms/expendableGroup/ExpendableGroup';
import VisitingCardForm from '../../../../../components/forms/compte/visitingCardForm/VisitingCardForm';
import FileInput from '../../../../../components/molecules/formComponents/fileInput/FileInput';
import RapportLogo from '../../../../../components/atoms/icons/monCompte/rapportLogo/RapportLogo';
import { checkImage, getImageInfos } from '../../../../../utils/Utils';
import Lock from '../../../../../components/atoms/icons/monCompte/lock/Lock';
import { Link } from 'react-router-dom';
import ArrowSm from '../../../../../components/atoms/icons/general/arrowSm/ArrowSm';
import ProfilForm from '../../../../../components/forms/compte/profilForm/ProfilForm';
import { useWindowSize } from '@uidotdev/usehooks';
import AppContext from '../../../../../context/AppContext';
import { fetchUserDataApi } from '../../../../../api/CompteApi';
import ForgotPasswordModal from '../../../../../components/forms/modals/compte/forgotPasswordModal/ForgotPasswordModal';
import {
  createVisitingCardApi,
  fetchVisitingCardApi,
  fetchVisitingCardsByUuidApi,
  updateVisitingCardApi,
} from '../../../../../api/VisitingCardApi';

function MonProfil({ navbarRef }) {
  const [visitingCards, setVisitingCards] = useState([]);
  const [importCardError, setImportCardError] = useState('');
  const [userData, setUserData] = useState({});

  const { width } = useWindowSize();
  const { createNotification, getUserId, setModalContent, setModalVisible, getUserData } = useContext(AppContext);

  useEffect(() => {
    fetchCartesVisites();
    fetchUserData();
  }, []);

  async function fetchUserData() {
    setUserData(await getUserData());
  }

  async function fetchCartesVisites() {
    try {
      const response = (await fetchVisitingCardsByUuidApi(getUserId())).data;
      setVisitingCards(response);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de vos cartes de visite</>,
        'var(--red)',
        'var(--grey)'
      );
    }
  }

  function generateCardName(visitingCards, imported) {
    const genericNamesCount = visitingCards.filter(card => card.card_name.match(/carte\s\d+/)).length;
    return `carte ${genericNamesCount + 1} ${imported ? '(importée)' : ''}`;
  }

  async function importVisitingCard(e) {
    const file = e.target.files[0];
    if (!file) return;

    let errorMsg = checkImage(file, 20);
    if (!errorMsg) {
      try {
        const card = (await createVisitingCardApi(generateCardName(visitingCards, true), 'imported_card'))
          .data;
        const response = (
          await updateVisitingCardApi(card.id, {
            ...card,
            images: { card: await getImageInfos(e) },
          })
        ).data;

        setVisitingCards(values => [...values, response]);
      } catch (error) {
        createNotification(
          <>Une erreur est survenue lors de l'importation de votre carte de visite</>,
          'var(--red)',
          'var(--grey)'
        );
      }
    }
    setImportCardError(errorMsg);
  }

  async function createVisitingCard() {
    try {
      const newCard = (await createVisitingCardApi(generateCardName(visitingCards))).data;
      setVisitingCards(values => [...values, newCard]);
      createNotification(<>Votre carte de visite a été créée avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création de votre estimation</>,
        'var(--red)',
        'var(--grey)'
      );
    }
  }

  async function refreshCardData(cardId) {
    try {
      let tmp = [...visitingCards];
      if (cardId) {
        const response = (await fetchVisitingCardApi(cardId)).data;

        tmp.forEach(card => {
          if (card.id === cardId) {
            card.card_name = response.card_name;
          }
        });
      } else tmp = (await fetchVisitingCardsByUuidApi(getUserId())).data;

      setVisitingCards(tmp);
    } catch (error) {}
  }

  function showForgotPasswordModal() {
    setModalContent({ title: 'Mot de passe oublié', content: <ForgotPasswordModal email={userData.email} /> });
    setModalVisible(true);
  }

  return (
    <section className='container'>
      <div className='mon-profil-container'>
        <div>
          <Card>
            <h2>MES CARTES DE VISITE ET MENTIONS LÉGALES</h2>
            <div className='visiting-card-buttons'>
              <FileInput
                onChange={importVisitingCard}
                error={importCardError}
                name='import-visiting-card'
                removeButton={false}
                padding='10px 20px'
                buttonText='Importer une carte de visite'
                bgColor='var(--secondary-color)'
              />
              <FilledButton onClick={createVisitingCard} padding='10px 20px'>
                Créer&nbsp;une&nbsp;carte
              </FilledButton>
            </div>
            <ExpendableGroup
              refreshData={refreshCardData}
              id='visiting-cards-expendable-group'
              data={visitingCards.map((card, key) => {
                return {
                  id: card.id,
                  title: card.card_name,
                  is_default: card.is_default,
                  content: (
                    <VisitingCardForm
                      refreshData={refreshCardData}
                      navbarRef={navbarRef}
                      visitingCardData={card}
                      id={key}
                    />
                  ),
                };
              })}
            />
          </Card>
          {width <= 992 && (
            <div className='mon-profil-responsive-buttons'>
              <FilledButton bgColor='var(--light-grey)' padding='20px' color='var(--grey)'>
                <RapportLogo />
                Modifier mon logo sur l'en-tête de mes rapports d'estimation
              </FilledButton>
              <FilledButton bgColor='var(--light-grey)' padding='20px' color='var(--grey)'>
                <RapportLogo rotate='180deg' />
                Modifier mon logo en pieds de pages de mes rapports d'estimation
              </FilledButton>
            </div>
          )}
          <Card className='mt-lg mon-profil' bgColor='var(--light-grey)'>
            <h2 className='mb-lg'>MON PROFIL</h2>
            <ProfilForm userData={userData} />
          </Card>
          {width <= 992 && (
            <>
              <div className='mon-profil-responsive-buttons'>
                <FilledButton
                  bgColor='var(--grey)'
                  padding='20px'
                  color='var(--light-grey)'
                  onClick={showForgotPasswordModal}>
                  <Lock />
                  Modifier mon mot de passe
                </FilledButton>
              </div>
              <div className='mon-profil-links'>
                <Link className='text-grey link-with-arrow' to='TODO'>
                  Désactiver mon compte
                  <ArrowSm color='var(--grey)' rotation='180deg' />
                </Link>
                <Link className='text-grey link-with-arrow' to='TODO'>
                  Supprimer mon compte
                  <ArrowSm color='var(--grey)' rotation='180deg' />
                </Link>
              </div>
            </>
          )}
        </div>
        {width > 992 && (
          <div className='flex f-column gap-lg'>
            <FilledButton bgColor='var(--light-grey)' padding='20px' color='var(--grey)'>
              <RapportLogo />
              Modifier mon logo sur l'en-tête de mes rapports d'estimation
            </FilledButton>
            <FilledButton bgColor='var(--light-grey)' padding='20px' color='var(--grey)'>
              <RapportLogo rotate='180deg' />
              Modifier mon logo en pieds de pages de mes rapports d'estimation
            </FilledButton>
            <FilledButton
              bgColor='var(--grey)'
              padding='20px'
              color='var(--light-grey)'
              onClick={showForgotPasswordModal}>
              <Lock />
              Modifier mon mot de passe
            </FilledButton>
            <div className='mon-profil-links'>
              <Link className='text-grey link-with-arrow' to='TODO'>
                Désactiver mon compte
                <ArrowSm color='var(--grey)' rotation='180deg' />
              </Link>
              <Link className='text-grey link-with-arrow' to='TODO'>
                Supprimer mon compte
                <ArrowSm color='var(--grey)' rotation='180deg' />
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default MonProfil;
