import React from 'react';
import './OrganizationalChart.css';

function OrganizationalChart({ shareholders, bordered = false }) {
  return (
    <div className={`mt-md ${bordered ? 'organizational-chart-bordered' : ''}`}>
      <table className='table-organizational-container'>
        <thead>
          <tr>
            <th>Actionnaires et bénéficiaires effectifs</th>
          </tr>
        </thead>
        <tbody>
          {shareholders.map((shareholder, index) => (
            <tr key={index}>
              <td>
                <div className="shareholder-info">
                  <div className="table-organizational-left-side">
                    <p className="shareholder-name">{shareholder.name}</p>
                    <p className="shareholder-role">{shareholder.role}</p>
                  </div>
                  <div className="table-organizational-right-side">
                    <p className="percentage-shares-organizational">{shareholder.percentage}</p>
                    <p className="percentage-shares-details">des titres et des votes</p>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrganizationalChart;