import { Link } from 'react-router-dom';
import './SummaryPdf.css';
import React from 'react';

function SummaryPdf({ summaryChapters }) {
  //   data = [
  //     {
  //       title: '',
  //       page: ,
  //       subItems: [
  //         { subtitle: '', page:  },
  //       ],
  //     },
  //   ];

  function generateDots(title) {
    const maxLength = 180;
    const numDots = maxLength - title.length;
    return '.'.repeat(Math.max(0, numDots));
  }

  return (
    <div className='summary-pdf-container'>
      {summaryChapters.map((SummaryChapter, index) => (
        <div key={index} className='summary-chapter-container'>
          <a href={'#' + generateHrefPdf(SummaryChapter.title)} className='chapter-title-container'>
            <span className='chapter-title'>
              {index + 1}. {SummaryChapter.title}
            </span>
            <span className='summary-dots-line'>{generateDots(`${index}.${SummaryChapter.title}`)}</span>
            <span>{SummaryChapter.page}</span>
          </a>
          {SummaryChapter.subItems &&
            SummaryChapter.subItems.map((item, subIndex) => (
              <a href={'#' + generateHrefPdf(item.subtitle)} key={subIndex} className='chapter-subtitle-container'>
                <span className='subtitle-bullet-point'>•</span>
                <span>{item.subtitle}</span>
                <span className='summary-dots-line'>{generateDots(item.subtitle)}</span>
                <span>{item.page}</span>
              </a>
            ))}
        </div>
      ))}
    </div>
  );
}

export default SummaryPdf;

export function generateHrefPdf(title) {
  if (!title) return '';
  return title
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replaceAll(/[^a-zA-Z0-9 ]/g, '')
    .replaceAll(' ', '-')
    .toLowerCase();
}

export function generateSummaryChapters(
  pdfContainerRef,
  actualPage,
  summaryChapters,
  dephtLevel,
  exceptions = ["RAPPORT D'ESTIMATION", 'Sommaire']
) {
  const node = pdfContainerRef.current ? pdfContainerRef.current : pdfContainerRef;

  if (node.getAttribute('custom-page-number')) actualPage = node.getAttribute('custom-page-number');

  if (node.localName === 'h2' && node.getAttribute('custom-visibility') !== '0')
    summaryChapters.push({ title: node.innerText, page: actualPage });

  if (node.localName === 'h3' && node.getAttribute('custom-visibility') !== '0') {
    const lastChapter = summaryChapters[summaryChapters.length - 1];
    if (lastChapter) {
      if (!lastChapter.subItems) lastChapter.subItems = [];
      lastChapter.subItems.push({ subtitle: node.innerText, page: actualPage });
    }
  }

  dephtLevel++;
  if (dephtLevel > 5) return summaryChapters;

  if (node?.children)
    for (const child of node?.children) {
      summaryChapters = generateSummaryChapters(child, actualPage, summaryChapters, dephtLevel, exceptions);
    }

  return summaryChapters;
}
