import React from 'react';
import './DescriptiveText.css';
import H3Pdf from '../h3Pdf/H3Pdf';

// data = {
//   title: '',
//   content: [
//     {
//       normalText: '',
//       boldText: '',
//     },
//   ],
// };

function DescriptiveText({ data, className = '' }) {
  return (
    <div className={`normal-bold-texts-group-container-pdf ${className}`}>
      {data?.title && <H3Pdf title={data.title} />}
      <div className='normal-bold-texts-container-pdf'>
        {data?.content.map((textGroup, index) => {
          return (
            <p key={index} className='normal-bold-text-pdf'>
              {textGroup.normalText}&nbsp;
              <span>{textGroup.boldText}</span>
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default DescriptiveText;
