import './ParcoursTdsFdc.css';
import React, { useEffect, useContext } from 'react';
import ParcoursCarousel from '../../organisms/parcoursCarousel/ParcoursCarousel';
import TrouverSociete from '../../forms/parcours/titresSocietes/step2/TrouverSociete';
import DefinitionAffaire from '../../forms/parcours/titresSocietes/step3/DefinitionAffaire';
import BilanActif from '../../forms/parcours/titresSocietes/step4/BilanActif';
import BilanPassif from '../../forms/parcours/titresSocietes/step5/BilanPassif';
import { ParcoursContext } from '../../../context/ParcoursContext';
import StarterTdsFdc from '../../forms/parcours/titresSocietes/step1/StarterTdsFdc';
import ComptesResultat from '../../forms/parcours/titresSocietes/step6/ComptesResultat';
import Retraitements from '../../forms/parcours/titresSocietes/step7/Retraitements';
import RayonnementImplantation from '../../forms/parcours/titresSocietes/step8/RayonnementImplantation';
import Clientele from '../../forms/parcours/titresSocietes/step9/Clientele';
import LocauxActivite from '../../forms/parcours/titresSocietes/step10/LocauxActivite';
import EtatLocatif from '../../forms/parcours/titresSocietes/step11/EtatLocatif';
import NormesAccessibilite from '../../forms/parcours/titresSocietes/step12/NormesAccessibilite';
import RatioClefs from '../../forms/parcours/titresSocietes/step13/RatioClefs';

function ParcoursTdsFdc() {
  const { estimationValues, setInputsNames, step, dependanceLocalCommercial, setStepsSummary, typesCommerce } =
    useContext(ParcoursContext);

  useEffect(() => {
    initParcours();
  }, []);

  useEffect(() => {
    setInputsNames(array => {
      array[11] = dependanceLocalCommercial ? 'etat_materiel' : 'accessibilite_pmr';
      array[2] = estimationValues?.infos?.activite?.id ? 'enseigne' : 'activite';
      return array;
    });
  }, [estimationValues]);

  function setTdsFdcInputsNames() {
    setInputsNames([
      '', // starter
      'raison_sociale',
      estimationValues?.infos?.activite?.id ? 'enseigne' : 'activite',
      'capital_souscrit',
      'capitaux_propres',
      'ventes_marchandise',
      'remunerations_dirigeants',
      'type_ville0',
      'type_clientele',
      'surface_locaux',
      'soumission_bail',
      dependanceLocalCommercial ? 'etat_materiel' : 'accessibilite_pmr',
      'salaries',
    ]);
  }

  function initParcours() {
    setTdsFdcInputsNames();

    setStepsSummary([
      {
        name: "L'entreprise",
        steps: [1, 2, 3],
      },
      {
        name: 'Les chiffres',
        steps: [4, 5, 6, 7],
      },
      {
        name: "L'emplacement",
        steps: [8, 9, 10, 11, 12],
      },
      {
        name: 'Le complément',
        steps: [13],
      },
    ]);
  }

  return (
    <>
      <ParcoursCarousel addBottomMargin={step == 2} step={step}>
        <StarterTdsFdc index={1} />
        <TrouverSociete index={2} />
        <DefinitionAffaire index={3} />
        <BilanActif index={4} />
        <BilanPassif index={5} />
        <ComptesResultat index={6} />
        <Retraitements index={7} />
        <RayonnementImplantation index={8} />
        <Clientele index={9} />
        <LocauxActivite index={10} />
        <EtatLocatif index={11} />
        <NormesAccessibilite index={12} />
        <RatioClefs index={13} />
      </ParcoursCarousel>
    </>
  );
}

export default ParcoursTdsFdc;
