import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/monCompte/lock.svg';

function Lock({ width = '30px', className = '', onClick, color = 'var(--main-color)' }) {
  return (
    <Icon
      style={{ cursor: onClick ? 'pointer' : 'auto' }}
      width={width}
      fill={color}
      height={width}
      className={className + (onClick ? ' clickable-icon' : '')}
      onClick={onClick}
    />
  );
}

export default Lock;
