import { Link } from 'react-router-dom';
//import EmeLogo from '../../atoms/logos/emeLogo/EmeLogo';
import './Footer.css';
import React from 'react';
import RoundedDiv from '../../atoms/icons/general/roundedDiv/RoundedDiv';

function Footer() {
  return (
    <>
      <section className='footer-container'>
        <RoundedDiv className='footer-top' />
        <div className='container footer-content'>
          <div>
            {/*<EmeLogo width='300px' className='footer-logo' />*/}
            <ul>
              <li>
                <Link to='mailto:contact@estimermoncommerce.fr'>contact@estimermoncommerce.fr</Link>
              </li>
              <li>41-43 rue de Cronstadt</li>
              <li>75015 Paris</li>
            </ul>
          </div>
          <div>
            <div className='footer-title'>Liens légaux</div>
            <ul>
              <li>
                <Link to='/politique-confidentialite'>Politique de confidentialité</Link>
              </li>
              <li>
                <Link to='/mentions-legales'>Mentions légales</Link>
              </li>
              <li>
                <Link to='/parametrage-cookies'>Gérer les cookies</Link>
              </li>
              <li>
                <Link to='/cgu'>C.G.U</Link>
              </li>
              <li>
                <Link to='/cgv'>C.G.V</Link>
              </li>
            </ul>
          </div>
          <div>
            <div className='footer-title'>Ressources</div>
            <ul>
              <li>
                <Link to='/contact'>Nous contacter</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
