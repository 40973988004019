import React from 'react';
import './SharedAquiredChart.css';
import OrganizationalChart from './OrganizationalChart';

function SharedAquiredChart({ companyName, subsidiaries }) {

  const shareholders = [
    {
      name: "Philippe Durand",
      role: "Président",
      percentage: "50%",
    },
    {
      name: "Sophie Durand",
      role: "Directeur général",
      percentage: "40%",
    },
    {
      name: 'Charles Dupond',
      role: 'Associé',
      percentage: '10%',
    },
  ];

  return (
    <div className='mt-md'>
      <div className='shared-aquired-chart'>
        <div className='company-name'>{companyName?.toUpperCase()}</div>
        <div className="partenaires">L'entreprise détient des titres dans :</div>
        <div className='subsidiaries'>
          {subsidiaries.map((sub, index) => (
            <div key={index} className="subsidiary-card">
              <OrganizationalChart shareholders={shareholders} bordered={true} />
              <div className="subsidiary-info">
                <div className="card-title">{sub.name}</div>
                <div className="card-detail">Siren : {sub.siren}</div>
                <div className="card-detail">{sub.percentage} des titres et des votes</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SharedAquiredChart;