import React, { Fragment, useContext, useEffect, useState } from 'react';
import Breadcrumbs from '../../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../../components/molecules/banner/Banner';
import { useNavigate, useParams } from 'react-router-dom';
import FilledButton from '../../../../components/molecules/buttons/filledButton/FilledButton';
import AppContext from '../../../../context/AppContext';
import {
  addSecondaryYearApi,
  deleteSecondaryYearApi,
  fetchEstimationWithSecondaryYearsApi,
} from '../../../../api/EstimationApi';
import Input from '../../../../components/molecules/formComponents/input/Input';
import Card from '../../../../components/molecules/cards/card/Card';
import './Synthese.css';
import UnfilledButton from '../../../../components/molecules/buttons/unfilledButton/UnfilledButton';
import { DateToString, formatDate } from '../../../../utils/Utils';
import Download from '../../../../components/atoms/icons/general/download/Download';
import Plus from '../../../../components/atoms/icons/general/plus/Plus';
import Rapport from '../../../../components/atoms/icons/monCompte/rapport/Rapport';
import { Link } from 'react-router-dom';
import Cross from '../../../../components/atoms/icons/general/cross/Cross';
import Trash from '../../../../components/atoms/icons/general/trash/Trash';
import AddBilanYearModal from '../../../../components/forms/modals/addBilanYearModal/AddBilanYearModal';

function Synthese() {
  const { estimationIdParam } = useParams();
  const [estimationData, setEstimationData] = useState([]);
  const [addYearInputValue, setAddYearInputValue] = useState('');
  const { createNotification, setModalVisible, setModalContent, setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!/^\d+$/.test(estimationIdParam)) return navigate('/404');
    setAppLoaderVisible(true);

    fetchEstimationWithSecondaryYears();
  }, [estimationIdParam]);

  async function fetchEstimationWithSecondaryYears() {
    try {
      const data = (await fetchEstimationWithSecondaryYearsApi(estimationIdParam)).data;
      if (!data.base_estimation.length) return navigate('/404');

      let estimationYears = [];
      if (data.base_estimation) {
        data.base_estimation[0].isMainYear = true;
        estimationYears = [...data.base_estimation, ...data.related_estimations];
        estimationYears.sort((a, b) => getBilanYear(a) - getBilanYear(b));
      } else {
        estimationYears = [data.estimation];
      }

      setEstimationData(estimationYears);
      setAppLoaderVisible(false)
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de votre estimation.{' '}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        'var(--red)',
        'var(--grey)'
      );
    }
  }

  function getBilanYear(bilan) {
    return Number(bilan.infos.date_bilan.slice(-4));
  }

  function showAddYearForm(futureBilan, estimationData, addYearInputValue) {
    const bilan = futureBilan ? estimationData[estimationData.length - 1] : estimationData[0];
    const splitedDate = bilan.infos.date_bilan.split(' ')[0].split('/').map(Number);
    const date = futureBilan
      ? new Date(splitedDate[2] + 1, splitedDate[1] - 1, splitedDate[0])
      : new Date(splitedDate[2] - 1, splitedDate[1] - 1, splitedDate[0]);

    setAddYearInputValue();

    setModalContent({
      title: 'Ajouter un bilan',
      content: <AddBilanYearModal bilanYear={formatDate(DateToString(date))} onSubmit={addEstimationYear} />,
    });

    setModalVisible(true);
  }

  async function deleteSecondaryYear(id) {
    try {
      await deleteSecondaryYearApi(id);

      await fetchEstimationWithSecondaryYears();
      createNotification(<>Le bilan a été supprimé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression du bilan. Veuillez réessayer plus tard</>,
        'var(--red)',
        'var(--grey)'
      );
    }
    setModalVisible(false);
  }

  async function addEstimationYear(date) {
    try {
      const id = (await addSecondaryYearApi(estimationIdParam, date)).data.id;

      setModalVisible(false);
      navigate(`/detail-parcours/secondaire/${id}`);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création du bilan. Veuillez réessayer plus tard</>,
        'var(--red)',
        'var(--grey)'
      );
    }
  }

  function showDeleteSecondaryYearModal(e, bilan) {
    e.preventDefault();

    setModalContent({
      title: 'Supprimer le bilan',
      content: (
        <>
          <p>Voulez-vous supprimer le bilan {getBilanYear(bilan)} ? </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                setModalContent({ title: '', content: <></> });
              }}>
              Annuler
            </UnfilledButton>
            <FilledButton padding='10px 25px' onClick={() => deleteSecondaryYear(bilan.id)}>
              Valider
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  function getInfoField(field, estimationData) {
    if (field === 'activite') return estimationData[0]?.infos?.activite?.description;

    if (field === 'adresse')
      return (
        estimationData[0]?.infos?.rue +
        ' ' +
        estimationData[0]?.infos?.code_postal +
        ' ' +
        estimationData[0]?.infos?.ville
      );

    return estimationData[0]?.infos[field];
  }

  function getParcoursUrl(bilan) {
    if (bilan.isMainYear)
      return `/detail-parcours/titres-de-societe/${bilan?.id}`;

    return `/detail-parcours/secondaire/${bilan?.id}`;
  }

  return (
    <section className='page-container container'>
      <Breadcrumbs
        routes={[
          { name: 'Accueil', to: '/' },
          { name: 'Estimer : Synthèse', to: '/synthese' },
        ]}
      />
      <Banner title='Synthèse' subtitle={estimationData[0]?.infos?.enseigne} />
      <div className='synthese-container'>
        <div>
          <Card borderRadius padding='30px'>
            <h2 className='synthese-title'>Modifier / consulter mon estimation</h2>
            <hr className='synthese-hr' />
            <p className='synthese-bilans-subtitle'>
              Consolidez facilement votre estimation en intégrant des années supplémentaires (en cliquant sur
              <Plus width='20px' color='var(--main-color)' />)
            </p>
            <div className='synthese-bilans-container'>
              {estimationData.length < 4 && (
                <>
                  <div
                    onClick={() => showAddYearForm(false, estimationData, addYearInputValue)}
                    className='synthese-bilan-button'>
                    <Rapport className='synthese-rapport-icon' />
                    <UnfilledButton padding='5px' borderRadius='50%'>
                      <Plus color='var(--main-color)' width='20px' className='clickable-icon' />
                    </UnfilledButton>
                  </div>
                  <div className='synthese-bilan-line' />
                </>
              )}
              {estimationData.map((bilan, key) => {
                return (
                  <Fragment key={key}>
                    {key > 0 && <div className='synthese-bilan-line' />}
                    <Link
                      to={getParcoursUrl(bilan)}
                      className={`synthese-bilan-button ${bilan.isMainYear ? 'synthese-main-bilan' : ''}`}>
                      <Rapport className='synthese-rapport-icon' />
                      {!bilan.isMainYear && (
                        <Trash
                          color='var(--white)'
                          onClick={e => showDeleteSecondaryYearModal(e, bilan)}
                          className='synthese-delete-bilan-icon'
                          width='25px'
                        />
                      )}
                      <p>
                        Comptes <br />
                        <span>{getBilanYear(bilan)}</span>
                      </p>
                      <FilledButton padding='2px 5px' fontSize='0.8rem'>
                        Accéder
                      </FilledButton>
                    </Link>
                  </Fragment>
                );
              })}
              {estimationData.length < 4 && (
                <>
                  <div className='synthese-bilan-line' />
                  <div
                    onClick={() => showAddYearForm(true, estimationData, addYearInputValue)}
                    className='synthese-bilan-button'>
                    <Rapport className='synthese-rapport-icon' />
                    <UnfilledButton padding='5px' borderRadius='50%'>
                      <Plus color='var(--main-color)' width='20px' className='clickable-icon' />
                    </UnfilledButton>
                  </div>
                </>
              )}
            </div>
          </Card>
          <FilledButton
            fontSize='1.3rem'
            padding='20px'
            to={`/pdf/${estimationIdParam}`}
            target='_blank'
            className='get-rapport-button'>
            <Download /> Obtenir mon rapport d'estimation <Download />
          </FilledButton>
        </div>
        <Card borderRadius padding='30px'>
          <h2 className='synthese-title'>{getInfoField('enseigne', estimationData)}</h2>
          <hr className='synthese-hr' />
          <table className='synthese-infos-table'>
            <tbody>
              <tr>
                <th>Adresse</th>
                <td>{getInfoField('adresse', estimationData)}</td>
              </tr>
              <tr>
                <th>Raison sociale</th>
                <td>{getInfoField('raison_sociale', estimationData)}</td>
              </tr>
              <tr>
                <th>Enseigne</th>
                <td>{getInfoField('enseigne', estimationData)}</td>
              </tr>
              <tr>
                <th>N° siret</th>
                <td>{getInfoField('siret', estimationData)}</td>
              </tr>
              <tr>
                <th>Activité</th>
                <td>{getInfoField('activite', estimationData)}</td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
    </section>
  );
}

export default Synthese;
